import axios from "axios";

// export const baseURL = "http://localhost:2323"                          // {Local}
// export const baseURL = "http://103.150.197.125:2323"                          // {Local}
export const baseURL = "https://cluapi.yotproject.or.id"                          // {Local}

// export const baseURL = "https://apitest.goes2nobel.com"                          // {Production}
//export const baseURL = "https://api.moljafurniture.com"                          // {Production}


export interface LoginPayload {
    username: String,
    password: String
}

export const loginAPI = async (payload: LoginPayload) => {
    try {
        const data = await axios.post(`${baseURL}/api/sign-in`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export interface clucode_check {
    clu_code: String
}


export interface RegisterPayload {
    clu_code: String,
    blok_name: String,
    number_home: String,
    username: String,
    password: String,
    fullname: String,
    role_id: String,
    email: String,
    phone_number: String,
    image_photo: String,
    status_user: String,
    
    status_tinggal: String,
    status_keluarga: String,
    
    data_rt: String,
    data_rw: String,

    rt_account_level: String,
    rt_sub_level_role: String,
    rw_account_level: String,
    rw_sub_level_role: String,
    kepala_kk: String,

    identity_nokk: String,
    identity_NIK: String,
    identity_gender: String,
    identity_agama: String,
    identity_statuspernikahan: String,
    identity_tempatlahir: String,
    identity_tanggallahir: String,
    identity_kewarganegaraan: String,
    identity_pekerjaan: String,
    code_generated: String,
}
// public class DataBatch
//     {
//         public int no { get; set; }
//         public double timestamp { get; set; }
//         public string blok_rumah { get; set; }
//         public int nomor_rumah { get; set; }
//         public string nomor_kk { get; set; }
//         public string nomor_nik { get; set; }
//         public string nama_lengkap { get; set; }
//         public string jenis_kelamin { get; set; }
//         public string tempat_lahir { get; set; }
//         public int tangggal_lahir { get; set; }
//         public string agama { get; set; }
//         public string pekerjaan { get; set; }
//         public string kewarganegaraan { get; set; }
//         public string status_tempat_tinggal { get; set; }
//         public string email { get; set; }
//         public string nomor_hp { get; set; }
//         public string alamat_ktp { get; set; }
//         public string upload_kk { get; set; }
//         public string email_input { get; set; }
//         public string status_hubungan_keluarga { get; set; }
//         public string status_pernikahan { get; set; }
//         public string pendidikan_terkahir { get; set; }
//         public string username { get; set; }
//         public object password { get; set; }
//         public string rt { get; set; }
//         public string rw { get; set; }
//     }

    export interface master_bacth 
    {
        clu_code: String,
        username: String,
        data_batch:  { [key: string]: any }[]
    }
export interface RegisterPayload_CLU {
    clu_name: String,
    clu_code: String,
    clu_address: String,
    clu_post: String,
    username: String,
    password: String,
    fullname: String,
    role_id: BigInt,
    email: String,
    phone_number: String,
    image_photo: String,
    status_user: String
}
export const Registerapi = async (payload: RegisterPayload) => {
    try {
        const data = await axios.post(`${baseURL}/api/sign-up`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const Registerapi_batch = async (payload: master_bacth) => {
    try {
        // console.log("Registerapi_batch DATA PAYLOAD " + payload.clu_code + " | "+ payload.username )
        const data = await axios.post(`${baseURL}/api/user-insertbatch`, payload)
        // console.log(data); // Log the data payload to the console
        
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const Registerapicluadmin = async (payload: RegisterPayload_CLU) => {
    try {
        const data = await axios.post(`${baseURL}/api/sign-up`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const Clucode_check = async (payload: clucode_check) => {
    try {
        const data = await axios.post(`${baseURL}/api/check_clucode`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export interface gethomenumberpayload {
    clu_code: String,
    blok_name: String
}
export const get_homenumber = async (payload: gethomenumberpayload) => {
    try {
        console.log("DATA PAYLOAD " + payload.clu_code + " | "+ payload.blok_name )
        const data = await axios.post(`${baseURL}/api/get_numberhome`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}