import { AdminRoutes } from "../routes"
import { jwtDecode } from 'jwt-decode';
// import React from 'react';

class Helper {

    static getTitleBar(pathname: string){
        if(pathname === "/dashboard") return "Dashboard"
        else return AdminRoutes.find(data => pathname==='/dashboard/' + data.url_var)?.name_var
    }

    static getVersion(){
        return "© Yot Project - clu v1.0.4[BetaFrontend]"
    }

    static getBydev(){
        return "Design by YPRO"
    }

    static getRoleName(roleID: number){
        switch(roleID){
            case 1:
                return "Administrator"
            case 2:
                return "User"
            case 3:
                return "Super Admin"
            default:
                return "Pengunjung"
        }
    }

    static expiredSession(token: string){
        //localStorage.clear();
        //console.log('Token: a', token);
		if(!token){
            return true
		}else{
            //const decode = jwtDecode(token) as any
            //const currentTime = Date.now() / 1000;
            
            //if(decode.exp < currentTime){
            //    return true
            //}else{
            //    return false
            //}
            const decode = jwtDecode(token) as { exp?: number };

            if (decode.exp) {
                const currentTime = Date.now() / 1000;
                return decode.exp < currentTime;
            }
		}
    }
}

export default Helper