import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserPayload_change_password } from '../../api/user-api';
import ErrorField from '../ErrorField';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment, { Moment } from 'moment';

interface UserFormExtend extends UserPayload_change_password {
    username: string,
    password: string,
    confirm_password: string,
}

type Props = {
    data?: any,
    onSubmit: (data: UserPayload_change_password) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const UserForm_changepass: React.FC<Props> = ({ data, onSubmit, onClose }) => {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });

        const [selectedDate_str, setSelectedDate_str] = useState('');
        const [selectedDate, setSelectedDate] = useState(new Date());

        const handleDateChanges = (value: string | Moment) => {
            if (typeof value === 'string') {
                setSelectedDate(new Date(value));
            } else {
                setSelectedDate(value.toDate());
                setSelectedDate_str(value.format('YYYY-MM-DD'));
            }
            // console.log("Date [Str] "+ selectedDate_str + " [Date] " + selectedDate)
        };
        const setdefaultValue = useCallback ((obj: any) => {
            Object.keys(obj).map(key => {
                return setValue(key, obj[key], { shouldValidate: true })
            })
        }, [setValue])

        useEffect(() => {
            // console.log(data)
            if(Object.keys(data).length !== 0){
                setdefaultValue(data)
            }
        }, [data, setdefaultValue])
    
        
        const onSubmitForm: SubmitHandler<UserFormExtend> = ({username, password, confirm_password}) => {
            // console.log("Password not match "+password+" != " + confirm_password);
            if(password !== confirm_password){
                toast.warning("Password not match "+password+" != " + confirm_password )
                return
            }

            const payload = {
                username, password, confirm_password
            } as UserPayload_change_password

            // if(Object.keys(data).length !== 0){
            //     delete payload.input_password
            // }

            onSubmit(payload)
        }
    
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                        <h3 className="text-xl font-medium text-white pb-4">{Object.keys(data).length > 0? "Form Update Password" : "Form Create User"}</h3>
    
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="fullname" className="label-form">Fullname</label>
                                <input type="text" id="fullname" className="input-form" placeholder="Full Name" 
                                    {...register('fullname', {
                                        required: 'Fullname is required',
                                        maxLength: {value: 25, message: 'Fullname is too long'}
                                    })}
                                />
                                <ErrorField errors={errors} name='fullname' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="username" className="label-form">Username</label>
                                <input type="text" id="username" className="input-form" placeholder="Username" 
                                    {...register('username', {
                                        required: 'Username is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='username' />
                            </div>
                        </div>

                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="password" className="label-form">Passsword</label>
                                    <input type="password" id="password" className="input-form"  placeholder="••••••••" 
                                        {...register("password", {
                                                required: "Password is required.",
                                                minLength: { value: 5, message: "Password must exceed 4 characters."}
                                        })}
                                        />
                                    <ErrorField errors={errors} name='password' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="confirm_password" className="label-form">Confirm Password</label>
                                    <input type="password" id="confirm_password" className="input-form" placeholder="••••••••" 
                                        {...register("confirm_password", { 
                                            required: "Confirm password is required." 
                                        })} />
                                    <ErrorField errors={errors} name='confirm_password' />
                                </div>
                            </div>
                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center pt-4 space-x-4">
                            <button type='submit' className="btn-primary px-7">Save & Active</button>
                            <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
}

export default UserForm_changepass;