import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {getAllUser_beforeupdate, UserPayload_change_data } from '../../api/user-api';
import ErrorField from '../ErrorField';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment, { Moment } from 'moment';

import PaymentAPI, { get_member_list, get_member_master_list, formCreatePayment} from '../../api/payment_api';

const LIST_STATUS_PERNIKAHAN = [
	{ id: 1, name: 'BELUM KAWIN' },
	{ id: 2, name: 'KAWIN' },
	{ id: 3, name: 'CERAI HIDUP' },
	{ id: 4, name: 'CERAI MENINGGAL' }
]
const LIST_RT = [
	{ id: 1, name: '0' }
]
const LIST_RW = [
	{ id: 1, name: '0' }
]
const LIST_AGAMA = [
	{ id: 1, name: 'ISLAM' },
	{ id: 2, name: 'KRISTEN (PROTESTAN)' },
	{ id: 3, name: 'KATOLIK' },
	{ id: 4, name: 'HINDU' },
	{ id: 5, name: 'BUDHA' },
	{ id: 6, name: 'KONGHUCU' }
]

const LIST_STATUS_KELUARGA = [
    { id: 1, name: 'KEPALA KELUARGA' },
    { id: 2, name: 'ISTRI' },
    { id: 3, name: 'ANAK' },
    { id: 4, name: 'ORANG TUA' },
    { id: 5, name: 'SAURDARA' },
    { id: 6, name: 'Lain-lain' }
]

const LIST_ROLE = [
	{ id: 1, name: 'Warga' }
]

const LIST_JENISKELAMIN = [
	{ id: 1, name: 'laki-laki' },
	{ id: 2, name: 'perempuan' }
]

interface payment_form_penagihanExtend extends formCreatePayment {
    confirm_password: string
}

type Props = {
    data?: any,
    onSubmit: (data: formCreatePayment) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const Payment_form_penagihan: React.FC<Props> = ({ data, onSubmit, onClose }) => {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });
        
        const [combobox_masterlist, set_masterlist] = useState<get_member_master_list[]>([])
        // const [combobox_masterlist, set_masterlist] = useState<get_member_master_list>()

        const [payload_getmember, set_getmember] = useState<get_member_list>({})

        const [selectedDate, setSelectedDate] = useState(new Date());
        const [selectedDate_str, setSelectedDate_str] = useState('');

        const userLocal = localStorage.getItem('dataUser')
        const user = userLocal? JSON.parse(userLocal || '') : null;

        const handleDateChanges = (value: string | Moment) => {
            console.log('> 1 ',value);
            if (typeof value === 'string') 
            {
                console.log('>> 1 ',selectedDate);
                setSelectedDate(new Date(value));
            } 
            else
            {
                const momentDate = moment(value);
                setSelectedDate(momentDate.toDate());
                console.log('>> 2 ',selectedDate);
            }
             //else {
            //     setSelectedDate(value.toDate());
            //     setSelectedDate_str(value.format('YYYY-MM-DD'));
            // }
            console.log('>>> ',selectedDate);
            setValue('identity_tanggallahir',selectedDate);
        };

        const setdefaultValue = useCallback ((obj: any) => {
            console.log(obj)
            Object.keys(obj).map(key => {
                if(key === 'identity_agama'){
                    console.log(key,' | ', data.identity_agama , ' - ' , LIST_AGAMA );
                    return setValue(key, LIST_AGAMA.find(item => item.name.toLowerCase() === data.identity_agama.toLowerCase())?.name, { shouldValidate: true })
                }
                else if(key === 'identity_gender'){
                    console.log(key,' | ', data.identity_gender , ' - ' , LIST_JENISKELAMIN );
                    return setValue(key, LIST_JENISKELAMIN.find(item => item.name.toLowerCase() === data.identity_gender.toLowerCase())?.name, { shouldValidate: true })
               }
               else if(key === 'identity_statuspernikahan'){
                console.log(key,' | ', data.identity_statuspernikahan , ' - ' , LIST_STATUS_PERNIKAHAN );
                   return setValue(key, LIST_STATUS_PERNIKAHAN.find(item => item.name.toLowerCase() === data.identity_statuspernikahan.toLowerCase())?.name, { shouldValidate: true })
              }
              else if(key === 'status_keluarga'){
                    console.log(key,' | ', data.status_keluarga , ' - ' , LIST_STATUS_KELUARGA );
                    return setValue(key, LIST_STATUS_KELUARGA.find(item => item.name.toLowerCase() === data.status_keluarga.toLowerCase())?.name, { shouldValidate: true })
             }else{
                    return setValue(key, obj[key], { shouldValidate: true })
                }
                
            })
        }, [setValue])

    const fetchUser = useCallback( async () => {

        payload_getmember.username = user.username;
        payload_getmember.code_generated = user.code_generated;

        payload_getmember.clu_code = data.clu_code;
        payload_getmember.opr_subject_var = 'payment';
        payload_getmember.role_type = 1;
        console.log(' fetchUser opr_getlist_mastermember :', payload_getmember);
        const res_2 = await PaymentAPI.opr_getlist_mastermember(payload_getmember);

        if(res_2.data){
            if(res_2.data.code === 0){
                console.log('opr_getlist_mastermember :', res_2.data.data);
                
                // res_2.data.data.forEach(async record => 
                // {
                //     console.log('opr_getlist_mastermember :', record.data.data);
                // });

                set_masterlist(res_2.data.data);
                console.log('combobox_masterlist :', combobox_masterlist);

                // setgetpayload_getlistmember_filter(res_2.data.data);
                
                // console.log('setManage_Fare :', data_Manage_Fare);
            }else{
                toast.error(res_2.data.message)
            }
        }
    }, [])

        useEffect(() => {
            fetchUser()
            if(Object.keys(data).length !== 0){
                // console.log('DATA : ',data)
                setdefaultValue(data)
            }
        }, [data, setdefaultValue])
    
        const onSubmitForm: SubmitHandler<payment_form_penagihanExtend> = ({clu_code,type_payment_code_var,list_member_code_var,descripsi_penagihan,waktu_penagihan,create_by }) => {
            
            const payload = {
                clu_code, type_payment_code_var,list_member_code_var,descripsi_penagihan,waktu_penagihan,create_by
            } as formCreatePayment

            onSubmit(payload)
        }
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
    
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                        <h3 className="text-xl font-medium text-white pb-4">FORM BUAT PENAGIHAN</h3>
    
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label className="label-form">Code Clu</label>
                                <input type="text" id="clu_code" className="input-form" disabled={true}
                                    {...register('clu_code', {
                                        required: 'clu_code is required',
                                        maxLength: {value: 50, message: 'clu_code is too long'}
                                    })}
                                />
                                <ErrorField errors={errors} name='clu_code' />
                            </div>
                            <div className="w-full">
                                <label className="label-form">Code Payment</label>
                                <input type="text" id="type_payment_code_var" className="input-form"   disabled={true}
                                    {...register('type_payment_code_var', {
                                        required: 'type_payment_code_var is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='type_payment_code_var' />
                            </div>
                        </div>
                        
                        
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="nominal_int" className="label-form">Nominal Pembayaran</label>
                                <input type="text" id="nominal_int" className="input-form" placeholder="" disabled={true}
                                    {...register('nominal_int', {
                                        required: 'nominal_int is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='nominal_int' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="type_payment_desc_var" className="label-form">Deskripsi Pembayaran</label>
                                <input type="text" id="type_payment_desc_var" className="input-form" placeholder="" disabled={true}
                                    {...register('type_payment_desc_var', {
                                        required: 'type_payment_desc_var is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='type_payment_desc_var' />
                            </div>
                            
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="descripsi_penagihan" className="label-form">Deskripsi Penarikan</label>
                                <input type="text" id="descripsi_penagihan" className="input-form" placeholder="" 
                                    {...register('descripsi_penagihan', {
                                        required: 'descripsi_penagihan is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='descripsi_penagihan' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="list_member_code_var" className="label-form">Kode Member List</label>
                                <select id="list_member_code_var" className="input-form" {...register('list_member_code_var', {required: 'identity_gender is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {combobox_masterlist.map((item, key) =>
                                        <option 
                                            key={key} 
                                            value={item.list_member_code_var}>
                                                {item.list_member_desc_var.toUpperCase()}
                                        </option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='list_member_code_varf' />
                            </div>
                            
                        </div>
                        <div className="w-full">
                            <div className="flex  flex-col">
                                <label className="label-form">Tanggal Penarikan</label>
                                <div className="flex items-center" style={{ height: '70px', width: '300px', marginTop: '-10px'  }}>
                                    <input type="date" id="waktu_penagihan" className="input-form" placeholder=''
                                        {...register('waktu_penagihan', {
                                            required: 'waktu_penagihan is required',
                                        })}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                    </svg>
                                </div>
                                <ErrorField errors={errors} name='waktu_penagihan' />
                            </div>
                        </div>
                        
                        <div className="flex items-center pt-4 space-x-4">
                            <button type='submit' className="btn-primary px-7">Save</button>
                            <button type='reset'  className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
}

export default Payment_form_penagihan;