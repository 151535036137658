import axios from "axios"
import { baseURL } from "./common-api"

export interface getmasterfare {
    username?: string,
    code_generated?: string,
    clu_code?: string,
    blok_name?: string,
    role_type?: number,
    opr_subject_var?: string
}

export interface response_getmasterfare {
    clu_code?: string,
    blok_name?: string,
    role_type?: number,
    create_by_var?: string,
    
    opr_desc_var?: string,
    opr_parameter_var?: string,
    opr_subject_var?: string,
    opr_value_var?: string,
    
}

export interface editmasterfare {
    clu_code?: string,
    blok_name?: string,
    role_type?: number,
    status_int?: number,
    edit_by_var?: string,

    opr_subject_var?: string,
    opr_parameter_var?: string,
    opr_value_var?: string
}

export interface formCreatePayment {
    username?: string
    code_generated?: string
    clu_code?: string
    type_payment_code_var?: string,
    list_member_code_var?: string,
    descripsi_penagihan?: string,
    waktu_penagihan?: string,
    create_by?: string,
    status_int?: string,
    nominal_kebersihan?: string,
    nominal_kas?: string,
    nominal_danasosial?: string,
    nominal_olahraga?: string,
    nominal_notes?: string,
    description_by_create?: string,
    
}

export interface payload_riwayatPenarikan {
    username?: string,
    code_generated?: string,
    description_by_create?: string,
    filterbystatus_int?: number,
    status_int?: number
}
export interface gettrx_penarikan {
    username?: string,
    code_generated?: string,
    clu_code?: string,
    blok_name?: string,
    role_type?: number,
    opr_subject_var?: string
}

export interface kas_pelimpahanpayload {
    username?: string,
    clu_code?: string,
    code_generated?: string,
    type_payment_int?: string
}

export interface kas_info {
    username?: string,
    clu_code?: string,
    code_generated?: string,
    type_payment_int?: string
}

export interface trx_updatepayment_api {
    clu_code: string,
    blok_name: string,
    code_generated: string,
    
    type_payment_code_var: string,
    mode_payment: string,
    dir: string,

    description_by_create: string,
    nominal_payment: string,
    notes_payment: string,
    images: any,
    status_int: string,
    status_after_int: string,
    edit_by_var: string,
    
    nominal_kas: string,
    nominal_kebersihan: string,
    nominal_danasosial: string,
    nominal_olahraga: string,
}


export interface trx_updatepayment_bendahara_api {
    code_generated?: string,
    username?: string,

    clu_code?: string,
    code_generated_target?: string,
    type_payment_code_var?: string,
    status_int?: string,
    description_by_create?: string,
    
    status_after_int?: string,
    edit_by_var?: string,
    kas_type?: string,

    nominal_int?: string,
    notes_payment?: string,
    
    nominal_kas?: string,
    nominal_danasosial?: string,
    nominal_kebersihan?: string,
    nominal_olahraga?: string,
}

export interface get_member_master_list {
    list_member_code_var: string,
    list_member_desc_var: string
}

export interface get_member_list {
    username?: string,
    code_generated?: string,
    clu_code?: string,
    role_type?: number,
    opr_subject_var?: string
}

class payment_api 
{
    static trx_submitpenarikan = async (payload: formCreatePayment) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-trx-payment', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    // "Content-Type": "application/json"
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static trx_getriwayat = async (payload: payload_riwayatPenarikan) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-trx-payment-history', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static trx_payment = async (payload: FormData) => {
        try {
            console.log('trx_payment : ',payload)
            const data = await axios.post(baseURL + '/api/payment-trx-payment-submit', payload, {
                onUploadProgress: (ProgressEvent) => {console.log(ProgressEvent.progress)},
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    // Accept: "application/json",
                    "Content-Type": "value"
                }
            })
            console.log(data)
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static trx_payment_update = async (payload: trx_updatepayment_bendahara_api) => {
        try {
            console.log('trx_payment_update : ',payload)
            const data = await axios.post(baseURL + '/api/payment-trx-changestatuspayment', payload, {
                onUploadProgress: (ProgressEvent) => {console.log(ProgressEvent.progress)},
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    // Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            console.log(data)
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static opr_getmasterfare = async (payload: getmasterfare) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-opr-getmasterfare', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static opr_editmasterfare = async (payload: editmasterfare) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-opr-editmasterfare', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            // console.log('opr_editmasterfare ',data)
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static summary_ipl = async (payload: gettrx_penarikan) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-trx-summaryIPL', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static opr_gettrx_request = async (payload: gettrx_penarikan) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-req-show', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static opr_getlist_mastermember = async (payload: get_member_list) => {
        try {
            const data = await axios.post(baseURL + '/api/payment-mastermember-show', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    static kas_summaryinfo = async (payload: kas_info) => {
        try {
            const data = await axios.post(baseURL + '/api/kas-summary', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    
}

export default payment_api
 