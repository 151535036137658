// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, deleteUser, getAllUser_byblok, updateUser, UserPayload,  UserPayload_byblok } from '../../../api/user-api'; //getAllUser,getAllUser_byaddress, 
import UserForm from '../../../components/modals/UserForm';
import SearchField from '../../../components/SearchField';
import TableFull from '../../../components/Table';
import ErrorField from '../../../components/ErrorField';
import ContactUsAPI, { ContactUsPayload } from '../../../api/contactus-api';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {};

const Mrt_2_Pengurus_RT: React.FC<Props> = () => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const [payloadUserPayload_byblok, set_UserPayload_byblok] = useState<UserPayload_byblok>({})
    
    const [data_codegenerated, set_data_codegenerated] = useState('')
    const [data_clucode, set_data_clucode] = useState("")
    const [data_blok_name, set_data_blok_name] = useState('')
    const [data_number_home, set_data_number_home] = useState('')

    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null;


    payloadUserPayload_byblok.username = user.username;
    payloadUserPayload_byblok.code_generated = user.code_generated;
    payloadUserPayload_byblok.status_user = "1";

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => {
        
        set_data_clucode(user.clu_code);
        set_data_codegenerated(user.code_generated);
        set_data_blok_name(user.blok_name);
        set_data_number_home(user.number_home);
        showLoader(true)

        // console.log("USER LOCAL : "+payloadUserPayload_byblok.clu_code)
        // const res = await getAllUser_byblok(payloadUserPayload_byblok as UserPayload_byblok)

        // showLoader(false)
        // console.log('Fetch Data Warga :', res)
        // if(res.data){
        //     if(res.data.code === 0){
        //         setDataUser(res.data.data)
        //         setFilterData(res.data.data)
        //     } else if(res.data.code === 99){
        //         navigate('/auth')
        //     }else{
        //         toast.error(res.data.message)
        //     }
        // }else{
        //     fetchUser()
        // }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const LIST_ROLE = [
        { id: 99, name: 'Super Admin' },
        { id: 1, name: 'Administrator' }
    ]

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await ContactUsAPI.get()

        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data){
            if(res.data.code === 0){
                setdefaultValue(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }, [navigate, setdefaultValue])

    const onSubmitForm: SubmitHandler<ContactUsPayload> = async ({address, telp, email, whatsapp_number}) => {
        showLoader(true)
        const res = await ContactUsAPI.update({address, telp, email, whatsapp_number } as ContactUsPayload)
        
        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data){
            if(res.data.code === 0){
                resetForm()
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }

    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleReceiveDataForm = async (data: UserPayload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            res = await createNewUser(data)
        }else{
            // res = await updateUser(selectedUser.id || 1, data)
        }

        // console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (data: UserPayload) => {
        const res = await deleteUser(data.id || 1)

        // console.log("DELETE USER :", res)
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
            }else{
                toast.error(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Name</span>,
            Footer: 'Fullname',
            accessor: 'fullname',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Username',
            Footer: 'Username',
            accessor: 'username',
        },
        {
            Header: 'Blok Rumah',
            Footer: 'blok_name',
            accessor: 'blok_name'
        },
        {
            Header: 'Nomor Rumah',
            Footer: 'number_home',
            accessor: 'number_home'
        },
        {
            Header: 'Jabatan Pengurus',
            Footer: 'rt_sub_level_role',
            accessor: 'rt_sub_level_role'
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i> */}
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        }
    ]

    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col px-6 md:px-10 pt-10'>

            <div className='w-full bg-gray-700 rounded-lg shadow-xl pt-3 pb-5 md:p-6'>
                <div className='flex items-center justify-between text-white px-8 pt-3 pb-8'>
                    <h2 className='font-semibold'>Manage Pengurus RT</h2>
                    <i className="ri-edit-box-line text-2xl hover:text-primary focus:ring-2 focus:ring-offset-2 focus:ring-primary cursor-pointer" onClick={()=>setIsUpdate(true)}></i>
                </div>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <fieldset disabled={!isUpdate} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">

                    
                    <div className="md:flex md:space-x-10  text-white  space-y-5 md:space-y-0">
                        <div className="flex-1">
                            <h1 >Clu Code</h1>
                            <input id="clu_code" type="text" value={data_clucode} disabled={true} className="text-input"/>
                            <ErrorField errors={errors} name='clu_code' />
                        </div>
                        <div className="flex-1">
                            <h1 >Nama Blok</h1>
                            <input id="nama_blok" type="text" value={data_blok_name} disabled={true} className="text-input"/>
                            <ErrorField errors={errors} name='nama_blok' />
                        </div>
                    </div>
                    <div className="md:flex md:space-x-10  text-white  space-y-5 md:space-y-0">
                        <div className="flex-1">
                            <h1 >LOGO RT</h1>
                            <input id="clu_code" type="text" disabled={true} className="text-input"/>
                            <ErrorField errors={errors} name='clu_code' />
                        </div>
                    </div>
                    {/* <div className="md:flex md:space-x-10 space-y-5 md:space-y-0 text-center mt-10">
                        <div className="w-full">
                            <label htmlFor="images" className="label-form">Images</label>
                            <div className='md:space-x-10 space-y-5'>
                                {dataAbout.images.map((image: any, key: number) =>
                                    <LazyLoadImage key={key} src={image} alt='' effect='blur' className='mx-1 md:m-0 h-44 border border-gray-100 rounded-md'  />
                                )}
                            </div>
                        </div>
                    </div> */}
                    {/* <!-- Modal footer --> */}
                    {isUpdate &&
                    <div className="flex items-center pt-8 space-x-4">
                        {/* <button type='submit' className="btn-primary px-7">Save</button> */}
                        <button type='reset' className="btn-secondary" onClick={resetForm}>Close</button>
                    </div>
                    }

                </fieldset>
                </form>
            </div>
            </div>
            <div className='flex item-center flex-col p-6'>

                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>Data Pengurus</h1>
                    </div>

                    {/* TABLE */}
                    <div className='flex justify-between md:items-center flex-col md:flex-row space-y-2 md:space-y-0 mb-3'>
                        {/* <button className='btn-primary' onClick={()=>showModalUser(true)}>Create New</button> */}
                        <SearchField placeholder='Search user...' onChange={handleSearch} />
                    </div>
                    <TableFull dataTable={filterData} columnTable={columns} />

                </div>
            </div>
            {/* {modalUser && <UserForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />} */}
        </>
    );
}

export default Mrt_2_Pengurus_RT;