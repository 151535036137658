// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, deleteUser, getAllUser_byblok, updateUser, UserPayload,  UserPayload_byblok} from '../../../api/user-api'; //getAllUser,getAllUser_byaddress, 
import SearchField from '../../../components/SearchField';
import TableFull from '../../../components/Table';
import ErrorField from '../../../components/ErrorField';
import ContactUsAPI, { ContactUsPayload } from '../../../api/contactus-api';
import PaymentAPI, { get_member_list, getmasterfare, gettrx_penarikan, payload_riwayatPenarikan, trx_updatepayment_api , formCreatePayment, trx_updatepayment_bendahara_api} from '../../../api/payment_api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';

import Payment_form_penagihan from '../../../components/modals/payment_form_penagihan';
import Payment_form_pembayaran from '../../../components/modals/payment_form_pembayaran_manual';
// import Payment_form_manage_buatpembayaran from '../../../components/modals/payment_form_manage_buatpembayaran';
// import FormMaster_listmember from '../../../components/modals/Master_list_member';

type Props = {};

const Trt_1_bendahara_1_report_ipl: React.FC<Props> = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const [modal_show_riwayat, showmodal_show_riwayat] = useState(false)
    const [modal_pembayaran, showModal_pembayaran] = useState(false)
    const [selectedformFormpembayaran, setFormpembayaran] = useState<formCreatePayment>({})

    const [modal_penagihan, showModal_penagihan] = useState(false)

    const [data_payload_riwayatPenarikan, set_payload_riwayatPenarikan] = useState<payload_riwayatPenarikan>({})
    // const [data_check_riwayat_ipl_filter, set_check_riwayat_ipl_filter] = useState([])
    // const [data_check_riwayat_ipl, set_check_riwayat_ipl] = useState([])

    const [data_check_riwayat_ipl, set_check_riwayat_ipl] = useState<DataItem[]>([]);
    const [data_check_riwayat_ipl_filter, set_check_riwayat_ipl_filter] = useState<DataItem[]>([]);


    const [payloadgetmasterfare, set_getmasterfare] = useState<getmasterfare>({})
    const [data_Manage_Fare_filter, setManage_Fare_filter] = useState([])
    const [data_Manage_Fare, setManage_Fare] = useState([])

    
    const [payloadtrx_updatepayment_bendahara_apin, set_trx_updatepayment_bendahara_api] = useState<trx_updatepayment_bendahara_api>({})

    const [payloadgettrx_penarikan, set_gettrx_penarikan] = useState<gettrx_penarikan>({})
    const [data_gettrx_penarikan_filter, setgettrx_penarikan_filter] = useState([])
    const [data_gettrx_penarikan, setgettrx_penarikan] = useState([])
    
    const [data_simmaryIPL_filter, set_data_simmaryIPL_filter] = useState([])
    const [data_simmaryIPL, set_data_simmaryIPL] = useState([])
    
    const [payload_getmember, set_getmember] = useState<get_member_list>({})
    const [data_getpayload_getlistmember_filter, setgetpayload_getlistmember_filter] = useState([])
    const [data_getpayload_getlistmember, setgetpayload_getlistmember] = useState([])

    // SHOW UI
    

    const [loader, showLoader] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    
    
    const [selectedformCreatePayment, setformCreatePayment] = useState<formCreatePayment>({})
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    // const [payloadUserPayload_byblok, set_UserPayload_byblok] = useState<UserPayload_byblok>({})
    
    const [data_codegenerated, set_data_codegenerated] = useState('')
    const [data_clucode, set_data_clucode] = useState("")
    const [data_blok_name, set_data_blok_name] = useState('')
    const [data_number_home, set_data_number_home] = useState('')

    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null;

    // payloadUserPayload_byblok.status_user = "1";

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => 
    {
        set_data_clucode(user.clu_code);
        set_data_codegenerated(user.code_generated);
        set_data_blok_name(user.blok_name);
        set_data_number_home(user.number_home);
        showLoader(true)

        // // ======================================================================= CONFIG FARE
        // data_payload_riwayatPenarikan.username = user.username;

        // data_payload_riwayatPenarikan.code_generated = '-';
        // data_payload_riwayatPenarikan.filterbystatus_int = 0;
        // data_payload_riwayatPenarikan.status_int = 1;
        // const res = await PaymentAPI.trx_getriwayat(data_payload_riwayatPenarikan);
        // console.log('trx_getriwayat : ',res)
        // showLoader(false)
        // if(res.data){
        //     if(res.data.code === 0){
        //         set_check_riwayat_ipl(res.data.data);
        //         set_check_riwayat_ipl_filter(res.data.data);
        //     } else if(res.data.code === 99){
        //         navigate('/auth')
        //     }else{
        //         toast.error(res.data.message)
        //     }
        // }else{
        //     fetchData()
        // }
        // ======================================================================= LIST PENARIKAN
        payloadgettrx_penarikan.username = user.username;
        payloadgettrx_penarikan.code_generated = user.code_generated;

        payloadgettrx_penarikan.clu_code = user.clu_code;
        payloadgettrx_penarikan.blok_name = user.blok_name;
        payloadgettrx_penarikan.opr_subject_var = 'payment';
        payloadgettrx_penarikan.role_type = 1;
        const res_1 = await PaymentAPI.opr_gettrx_request(payloadgettrx_penarikan);

        showLoader(false)
        // console.log('opr_gettrx_request :', res_1)
        if(res_1.data){
            if(res_1.data.code === 0){
                // console.log('opr_gettrx_request :', res_1.data.data);
                setgettrx_penarikan(res_1.data.data);
                setgettrx_penarikan_filter(res_1.data.data);
                
                // console.log('setManage_Fare :', data_Manage_Fare);
            } else if(res_1.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res_1.data.message)
            }
        }else{
            fetchData()
        }
        
        // ======================================================================= LIST Summary
        payloadgettrx_penarikan.username = user.username;
        payloadgettrx_penarikan.code_generated = user.code_generated;
        
        payloadgettrx_penarikan.clu_code = user.clu_code;
        payloadgettrx_penarikan.blok_name = user.blok_name;
        payloadgettrx_penarikan.opr_subject_var = 'payment';
        payloadgettrx_penarikan.role_type = 1;
        const res_3 = await PaymentAPI.summary_ipl(payloadgettrx_penarikan);

        showLoader(false)
        // console.log('summary_ipl :', res_3)
        if(res_3.data){
            if(res_3.data.code === 0){
                // console.log('opr_gettrx_request :', res_3.data.data);
                set_data_simmaryIPL(res_3.data.data);
                set_data_simmaryIPL_filter(res_3.data.data);
                
                // console.log('setManage_Fare :', data_Manage_Fare);
            } else if(res_3.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res_3.data.message)
            }
        }else{
            fetchData()
        }

        
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchData = useCallback( async () => {
        showLoader(true)
        payloadgetmasterfare.clu_code = user.clu_code;
        payloadgetmasterfare.blok_name = user.blok_name;
        payloadgetmasterfare.opr_subject_var = 'payment';
        payloadgetmasterfare.role_type = 1;
        const res = await PaymentAPI.opr_getmasterfare(payloadgetmasterfare);

        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                setdefaultValue(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }, [navigate])

    const payment_accept= async (data: trx_updatepayment_bendahara_api)  => {

        payloadtrx_updatepayment_bendahara_apin.clu_code = data.clu_code;
        payloadtrx_updatepayment_bendahara_apin.code_generated = user.code_generated;
        payloadtrx_updatepayment_bendahara_apin.username = user.username;
        
        payloadtrx_updatepayment_bendahara_apin.code_generated_target = data.code_generated;
        payloadtrx_updatepayment_bendahara_apin.edit_by_var = user.username;
        payloadtrx_updatepayment_bendahara_apin.status_after_int = '-1';
        payloadtrx_updatepayment_bendahara_apin.status_int = data.status_int;
        payloadtrx_updatepayment_bendahara_apin.type_payment_code_var = data.type_payment_code_var;
        payloadtrx_updatepayment_bendahara_apin.description_by_create = data.description_by_create;
        payloadtrx_updatepayment_bendahara_apin.kas_type = 'rt';
        
        payloadtrx_updatepayment_bendahara_apin.nominal_int = data.nominal_int;
        payloadtrx_updatepayment_bendahara_apin.notes_payment = data.notes_payment;
        
        payloadtrx_updatepayment_bendahara_apin.nominal_kas = data.nominal_kas;
        payloadtrx_updatepayment_bendahara_apin.nominal_danasosial = data.nominal_danasosial;
        payloadtrx_updatepayment_bendahara_apin.nominal_kebersihan = data.nominal_kebersihan;
        payloadtrx_updatepayment_bendahara_apin.nominal_olahraga = data.nominal_olahraga;
        
        const res = await PaymentAPI.trx_payment_update(payloadtrx_updatepayment_bendahara_apin)

        showLoader(false)
        toast.success('< Accept : ', res)
        if(res.data){
            if(res.data.code === 0){
                resetForm()
                fetchUser()
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.data.message)
        }
    }
    const payment_reject= async (data: trx_updatepayment_bendahara_api)  => {

        payloadtrx_updatepayment_bendahara_apin.clu_code = data.clu_code;
        payloadtrx_updatepayment_bendahara_apin.code_generated = user.code_generated;
        payloadtrx_updatepayment_bendahara_apin.username = user.username;
        
        payloadtrx_updatepayment_bendahara_apin.code_generated_target = data.code_generated;
        payloadtrx_updatepayment_bendahara_apin.edit_by_var = user.username;
        payloadtrx_updatepayment_bendahara_apin.status_int = data.status_int;

        payloadtrx_updatepayment_bendahara_apin.status_after_int = '0';
        payloadtrx_updatepayment_bendahara_apin.type_payment_code_var = data.type_payment_code_var;
        payloadtrx_updatepayment_bendahara_apin.description_by_create = data.description_by_create;
        payloadtrx_updatepayment_bendahara_apin.kas_type = 'rt';
        
        payloadtrx_updatepayment_bendahara_apin.nominal_int = data.nominal_int;
        payloadtrx_updatepayment_bendahara_apin.notes_payment = data.notes_payment;

        payloadtrx_updatepayment_bendahara_apin.nominal_kas = data.nominal_kas;
        payloadtrx_updatepayment_bendahara_apin.nominal_danasosial = data.nominal_danasosial;
        payloadtrx_updatepayment_bendahara_apin.nominal_kebersihan = data.nominal_kebersihan;
        payloadtrx_updatepayment_bendahara_apin.nominal_olahraga = data.nominal_olahraga;
        const res = await PaymentAPI.trx_payment_update(payloadtrx_updatepayment_bendahara_apin)

        showLoader(false)
        toast.success('< Reject : ', res)
        if(res.data){
            if(res.data.code === 0){
                resetForm()
                fetchUser()
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.data.message)
        }
    }
    const handleReceiveDataForm_payment = async (data: trx_updatepayment_api) => {
        showLoader(true)


        if(!data.images)
        {
            toast.error("IMAGE NULL")
            return;  
        }
        const formData = new FormData();

        formData.append('clu_code', data.clu_code);
        formData.append('code_generated', data.code_generated);
        formData.append('desc_payment', data.notes_payment);
        formData.append('edit_by_var', data.edit_by_var);
        formData.append('images', data.images);
        formData.append('mode_payment', data.mode_payment);
        formData.append('nominal_payment', data.nominal_payment);
        formData.append('status_int', data.status_int);
        formData.append('status_after_int', data.status_after_int);
        formData.append('description_by_create', data.description_by_create);
        formData.append('dir', data.dir);
        formData.append('type_payment_code_var', data.type_payment_code_var);
        
        const res = await PaymentAPI.trx_payment(formData)

        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                resetForm()
                fetchUser()
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.data.message)
        }
    }
    const handleReceiveDataForm = async (data_payload: formCreatePayment) => {
        showLoader(true)
        data_payload.username = user.username;
        data_payload.code_generated = user.code_generated;
        data_payload.create_by = user.username;
        const res = await PaymentAPI.trx_submitpenarikan(data_payload);

        showLoader(false)
        if(res.data){
            toast.success(res.data.message)
            resetForm();
            fetchUser();
        }else{
            toast.error(res.data.message)
        }
        // let res = null
        // if(!isUpdate){
        //     res = await createNewUser(data)
        // }else{
        //     // res = await updateUser(selectedUser.id || 1, data)
        // }

        // console.log('Create/Update User :', res)
        
        // if(res.data){
        //     if(res.data.code === 0){
        //         toast.success('Success')
        //         fetchUser()
        //         resetForm()
        //     }else{
        //         toast.error(res.data.message)
        //         showLoader(false)
        //     }
        // }else{
        //     alert(`${res.config?.url} ${res.message}`)
        // }
    }
    const show_detail_riwayat =  async (selectedData: formCreatePayment) => {
        showmodal_show_riwayat(false)
        data_payload_riwayatPenarikan.username = user.username;

        data_payload_riwayatPenarikan.code_generated = '-';
        data_payload_riwayatPenarikan.filterbystatus_int = 0;
        data_payload_riwayatPenarikan.status_int = 1;
        data_payload_riwayatPenarikan.description_by_create = selectedData.description_by_create;
        
        const res = await PaymentAPI.trx_getriwayat(data_payload_riwayatPenarikan);
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                const dataWithNoApp = res.data.data.map((item: DataItem, index: number) => ({
                    ...item,
                    no_app: index + 1 // Menambahkan nomor increment
                }));
                set_check_riwayat_ipl(dataWithNoApp);
                set_check_riwayat_ipl_filter(dataWithNoApp);
                showmodal_show_riwayat(true)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.data.message)
        }
    }
    const handle_ui_riwayatIPL =  async (selectedData: formCreatePayment) => {
        // console.log('TERPILIH', selectedData)
        // ======================================================================= CONFIG FARE
        if(selectedData.status_int == '1')
        {
            payloadgetmasterfare.username = user.username;
            payloadgetmasterfare.code_generated = user.code_generated;
    
            payloadgetmasterfare.clu_code = user.clu_code;
            payloadgetmasterfare.blok_name = user.blok_name;
            payloadgetmasterfare.opr_subject_var = 'payment';
            payloadgetmasterfare.role_type = 1;
            const res = await PaymentAPI.opr_getmasterfare(payloadgetmasterfare);
    
            if(res.data){
                if(res.data.code === 0){
                    Object.keys(res.data.data).map(key => {
                        if(res.data.data[key].opr_parameter_var == 'IPLPW'){
                            selectedData.nominal_kebersihan  = res.data.data[key].opr_value_var  
                        }
                        if(res.data.data[key].opr_parameter_var == 'IPKBL'){
                            selectedData.nominal_kas  = res.data.data[key].opr_value_var  
                        }
                        if(res.data.data[key].opr_parameter_var == 'IPDBL'){
                            selectedData.nominal_danasosial  = res.data.data[key].opr_value_var  
                        }
                    })
                    selectedData.nominal_olahraga  = '0'

                    // console.log('TERPILIH AFTER', selectedData)
                }else{
                    toast.error(res.data.message)
                }
            }else{
                toast.error(res.data.message)
            }
        }
        setIsUpdate(true)
        setFormpembayaran(selectedData)
        showModal_pembayaran(true)
    }
    
    const handle_ui_editpenarikan =  (selectedData: formCreatePayment) => {
        setformCreatePayment(selectedData)
        showModal_penagihan(true)
    }
    
    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showLoader(false)
        showmodal_show_riwayat(false)
        
        showModal_penagihan(false)
        showModal_pembayaran(false)
    }
    const columns_master_riwayatIPL = [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no_app',
            accessor: 'no_app',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        
                        <div className="flex items-center cursor-pointer" onClick={() => handle_ui_riwayatIPL(data)}>
                            <i className="ri-edit-box-line text-primary hover:text-green-400"></i>
                            <h1 className="ml-2 text-sm  text-primary hover:text-green-400">Detail</h1>
                        </div> 
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        },
        {
            Header: () => <span className='p-4'>Status Deskripsi</span>,
            Footer: 'status_desc',
            accessor: 'status_desc',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>No Home</span>,
            Footer: 'number_home',
            accessor: 'number_home',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nama</span>,
            Footer: 'nama_kepala_keluarga',
            accessor: 'nama_kepala_keluarga',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Clu Code</span>,
            Footer: 'clu_code',
            accessor: 'clu_code',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Blok Name</span>,
            Footer: 'blok_name',
            accessor: 'blok_name',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Payment</span>,
            Footer: 'description_by_create',
            accessor: 'description_by_create',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nominal</span>,
            Footer: 'nominal_int',
            accessor: 'nominal_int',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        // {
        //     Header: () => <span className='p-4'>Status</span>,
        //     Footer: 'status_int',
        //     accessor: 'status_int',
        //     Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        // },
    ]

    const columns_trx_penarikan = [
        {
            Header: () => <span className='p-4'>Kode Pembayaran</span>,
            Footer: 'type_payment_code_var',
            accessor: 'type_payment_code_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Deskripsi Pembayaran</span>,
            Footer: 'type_payment_desc_var',
            accessor: 'type_payment_desc_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Start Time</span>,
            Footer: 'time_billing_start',
            accessor: 'time_billing_start',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Total Nilai</span>,
            Footer: 'nominal_int',
            accessor: 'nominal_int',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                         <button type='reset' className="btn-secondary" onClick={() =>handle_ui_editpenarikan(data)}>BUAT PENARIKAN</button>
                           
                        {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_editpenarikan(data)}></i> */}
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        }
    ]
    const columns_sumamaryIPL = [
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <div className="flex items-center cursor-pointer" onClick={() => show_detail_riwayat(data)}>
                            <i className="ri-edit-box-line text-primary hover:text-green-400"></i>
                            <h1 className="ml-2 text-sm  text-primary hover:text-green-400">Detail</h1>
                        </div> 
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )
                return <></>
            }
        },
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>payment</span>,
            Footer: 'description_by_create',
            accessor: 'description_by_create',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Belum Bayar</span>,
            Footer: 'belum_bayar',
            accessor: 'belum_bayar',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Belum Dicheck</span>,
            Footer: 'belum_dicheck',
            accessor: 'belum_dicheck',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Sudah Selesai</span>,
            Footer: 'sudah_selesai',
            accessor: 'sudah_selesai',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    interface DataItem {
        description_by_create?: string;
        nama_kepala_keluarga?: string;
        opr_subject_var?: string;
        // tambahkan properti lain yang ada dalam objek
    }
    const handleSearch_riwayatipl = (event: any) => {
        event.preventDefault();
    
        const newData: DataItem[] = [...data_check_riwayat_ipl_filter];
        if (event.target.value) {
            const filtered = newData.filter((item: DataItem) => {
                return (
                    item.description_by_create?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.nama_kepala_keluarga?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.opr_subject_var?.toLowerCase().includes(event.target.value.toLowerCase())
                );
            }).map((item: DataItem, index: number) => ({
                ...item,
                no_app: index + 1 // Menambahkan nomor increment
            }));
            set_check_riwayat_ipl_filter(filtered);
        } else {
            const originalDataWithNo = data_check_riwayat_ipl.map((item: DataItem, index: number) => ({
                ...item,
                no_app: index + 1 // Menambahkan nomor increment
            }));
            set_check_riwayat_ipl_filter(originalDataWithNo);
        }
    }
    
    const handleSearch_penarikan = (event: any) => {
        event.preventDefault()

        const newData = [...data_Manage_Fare_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.time_billing_start?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.type_payment_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.type_payment_desc_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setManage_Fare_filter(filtered)
        }else{
            setManage_Fare_filter(data_Manage_Fare)
        }
    }
    const handleSearch_summaryIPL = (event: any) => {
        event.preventDefault()

        const newData = [...data_simmaryIPL_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.description_by_create?.toLowerCase().includes(event.target.value.toLowerCase()) 
                )
            });

            set_data_simmaryIPL_filter(filtered)
        }else{
            set_data_simmaryIPL_filter(data_simmaryIPL)
        }
    }
    const handleSearch_member= (event: any) => {
        event.preventDefault()

        const newData = [...data_Manage_Fare_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.time_billing_start?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.type_payment_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.type_payment_desc_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setManage_Fare_filter(filtered)
        }else{
            setManage_Fare_filter(data_Manage_Fare)
        }
    }
    const show_allriwayat = async () => {
        showmodal_show_riwayat(false)
        // toast.error("Show All Riwayat")
        // toast.success("Show All Riwayat")
        data_payload_riwayatPenarikan.username = user.username;

        data_payload_riwayatPenarikan.code_generated = '-';
        data_payload_riwayatPenarikan.filterbystatus_int = 0;
        data_payload_riwayatPenarikan.status_int = 1;
        data_payload_riwayatPenarikan.description_by_create = "";
        const res = await PaymentAPI.trx_getriwayat(data_payload_riwayatPenarikan);
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                const dataWithNoApp = res.data.data.map((item: DataItem, index: number) => ({
                    ...item,
                    no_app: index + 1 // Menambahkan nomor increment
                }));
                set_check_riwayat_ipl(dataWithNoApp);
                set_check_riwayat_ipl_filter(dataWithNoApp);
                // set_check_riwayat_ipl(res.data.data);
                // set_check_riwayat_ipl_filter(res.data.data);
                showmodal_show_riwayat(true)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error(res.data.message)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
                <h1 className='text-base font-semibold'>REPORT IPL</h1>
                <div className="border-2 border-dark rounded-lg ">
                    <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                        <h1 className='font-bold'>SUMMARY RIWAYAT IPL</h1>
                    </div>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch_summaryIPL} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={show_allriwayat}>Show All Riwayat</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={data_simmaryIPL_filter} columnTable={columns_sumamaryIPL} />
                    </div>
                </div>
                {modal_show_riwayat &&
                    <div className="border-2 border-dark rounded-lg mt-5">
                    <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                        <h1 className='font-bold'>RIWAYAT IPL WARGA</h1>
                    </div>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch_riwayatipl} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                {/* <button type='reset' className="btn-secondary" onClick={create_payment(create_payment)}>GENERATE REPORT</button> */}
                            </div>
                        </div>
                    </div>
                    <TableFull dataTable={data_check_riwayat_ipl_filter} columnTable={columns_master_riwayatIPL} />
                    </div>
                </div>
                }
            </div>
            
            {/* {modal_formaddpayment && <Payment_form_manage_buatpembayaran data={selectedpayloadgetmasterfare} onClose={resetForm} onSubmit={handleReceiveDataForm_editconfig} />} */}
            
            {/* {modal_formmasterlistmember && <FormMaster_listmember data={selectedpayloadgetmasterfare} onClose={resetForm} onSubmit={handleReceiveDataForm_master_listmember} />} */}
            
            
            <div className='flex item-center flex-col p-6'>
                {/* <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>PENARIKAN IPL</h1>
                    </div>

                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Buat Penarikan</button>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Ubah Tarif</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={filterData} columnTable={columns_payment} />
                </div> */}
                {/* <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>MEMBER PENARIKAN</h1>
                    </div>

                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Buat Penarikan</button>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Ubah Tarif</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={filterData} columnTable={columns_payment_member} />
                </div> */}
            </div>
            {modal_penagihan && <Payment_form_penagihan data={selectedformCreatePayment} onClose={resetForm} onSubmit={handleReceiveDataForm} />}
            {modal_pembayaran && <Payment_form_pembayaran isEditable={true} data={selectedformFormpembayaran} onClose={resetForm} onAccept={payment_accept} onReject={payment_reject} onSubmit={handleReceiveDataForm_payment} />}
        </>
    );
}

export default Trt_1_bendahara_1_report_ipl;