import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface FullScreenImageProps {
    src: string;
    alt: string;
    onClose: () => void;
}

const FullScreenImage: React.FC<FullScreenImageProps> = ({ src, alt, onClose }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <LazyLoadImage
                src={src}
                alt={alt}
                effect="blur"
                className="max-w-full h-full"
                style={{ height: '100vh', objectFit: 'contain'}}
            />
            <button onClick={onClose} className="absolute top-4 right-4 text-white text-2xl">×</button>
        </div>
    );
};

export default FullScreenImage;