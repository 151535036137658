import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {getAllUser_beforeupdate, UserPayload_change_data, UserPayload_change_data_before } from '../../api/user-api';
import ErrorField from '../ErrorField';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment, { Moment } from 'moment';

const LIST_STATUS_PERNIKAHAN = [
	{ id: 1, name: 'BELUM KAWIN' },
	{ id: 2, name: 'KAWIN' },
	{ id: 3, name: 'CERAI HIDUP' },
	{ id: 4, name: 'CERAI MENINGGAL' }
]
const LIST_RT = [
	{ id: 1, name: '0' }
]
const LIST_RW = [
	{ id: 1, name: '0' }
]
const LIST_AGAMA = [
	{ id: 1, name: 'ISLAM' },
	{ id: 2, name: 'KRISTEN (PROTESTAN)' },
	{ id: 3, name: 'KATOLIK' },
	{ id: 4, name: 'HINDU' },
	{ id: 5, name: 'BUDHA' },
	{ id: 6, name: 'KONGHUCU' }
]

const LIST_STATUS_KELUARGA = [
    { id: 1, name: 'KEPALA KELUARGA' },
    { id: 2, name: 'ISTRI' },
    { id: 3, name: 'ANAK' },
    { id: 4, name: 'ORANG TUA' },
    { id: 5, name: 'SAURDARA' },
    { id: 6, name: 'Lain-lain' }
]

const LIST_ROLE = [
	{ id: 1, name: 'Warga' }
]

const LIST_JENISKELAMIN = [
	{ id: 1, name: 'laki-laki' },
	{ id: 2, name: 'perempuan' }
]

interface UserFormExtend extends UserPayload_change_data {
    confirm_password: string
}

type Props = {
    data?: any,
    onSubmit: (data: UserPayload_change_data) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const UserForm: React.FC<Props> = ({ data, onSubmit, onClose }) => {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });

        const [payloadUserPayload_byaddress, setUserPayload_byaddress] = useState<UserPayload_change_data_before>({})
        let [payloadUserPayload_change_data, setUserPayload_change_data] = useState<UserPayload_change_data>({})
        
        // const [selectedDate, setSelectedDate] = useState('');
        const [selectedDate, setSelectedDate] = useState(new Date());
        const [selectedDate_str, setSelectedDate_str] = useState('');

        const handleDateChanges = (value: string | Moment) => {
            // if (typeof value === 'string') {
            //     // setSelectedDate(new Date(value));
            // } else {
            //     const momentDate = moment(value);
            //     // momentDate.subtract(1, 'days');
            //     setSelectedDate(momentDate.toDate());
            // }
            console.log('> 1 ',value);
            if (typeof value === 'string') 
            {
                console.log('>> 1 ',selectedDate);
                setSelectedDate(new Date(value));
            } 
            else
            {
                const momentDate = moment(value);
                setSelectedDate(momentDate.toDate());
                console.log('>> 2 ',selectedDate);
            }
             //else {
            //     setSelectedDate(value.toDate());
            //     setSelectedDate_str(value.format('YYYY-MM-DD'));
            // }
            console.log('>>> ',selectedDate);
            setValue('identity_tanggallahir',selectedDate);
        };

        const setdefaultValue = useCallback ((obj: any) => {
            console.log()
            Object.keys(obj).map(key => {
                if(key === 'identity_agama'){
                    // console.log(key,' | ', data.identity_agama , ' - ' , LIST_AGAMA );
                    return setValue(key, LIST_AGAMA.find(item => item.name.toLowerCase() === data.identity_agama.toLowerCase())?.name, { shouldValidate: true })
                }
                else if(key === 'identity_gender'){
                    // console.log(key,' | ', data.identity_gender , ' - ' , LIST_JENISKELAMIN );
                    return setValue(key, LIST_JENISKELAMIN.find(item => item.name.toLowerCase() === data.identity_gender.toLowerCase())?.name, { shouldValidate: true })
               }
               else if(key === 'identity_statuspernikahan'){
                    // console.log(key,' | ', data.identity_statuspernikahan , ' - ' , LIST_STATUS_PERNIKAHAN );
                   return setValue(key, LIST_STATUS_PERNIKAHAN.find(item => item.name.toLowerCase() === data.identity_statuspernikahan.toLowerCase())?.name, { shouldValidate: true })
              }
              else if(key === 'status_keluarga'){
                    // console.log(key,' | ', data.status_keluarga , ' - ' , LIST_STATUS_KELUARGA );
                    return setValue(key, LIST_STATUS_KELUARGA.find(item => item.name.toLowerCase() === data.status_keluarga.toLowerCase())?.name, { shouldValidate: true })
             }else{
                    return setValue(key, obj[key], { shouldValidate: true })
                }
                
            })
        }, [setValue])


        

    const fetchUser = useCallback( async () => {
        const res = await getAllUser_beforeupdate(data as UserPayload_change_data_before)
        if(res.data){
            if(res.data.code === 0){
                payloadUserPayload_change_data= res.data.data.rows[0];
                // console.log('getAllUser_beforeupdate ' , payloadUserPayload_change_data)
                setdefaultValue(payloadUserPayload_change_data)

                const momentDate = moment(payloadUserPayload_change_data.identity_tanggallahir);
                // setSelectedDate(momentDate);
                
                const dateFromMoment = momentDate.toDate();
                setSelectedDate(dateFromMoment);

            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error('NOT FOUND')
        }
    }, [])

        useEffect(() => {
            fetchUser()
            if(Object.keys(data).length !== 0){
                setdefaultValue(data)
            }
        }, [data, setdefaultValue])
    
        const onSubmitForm: SubmitHandler<UserFormExtend> = ({clu_code, code_generated, fullname,username,  email, phone_number, identity_gender, identity_agama,  identity_tempatlahir, identity_tanggallahir, identity_statuspernikahan, identity_kewarganegaraan, identity_pekerjaan, status_keluarga,  image_photo}) => {
            
            const payload = {
                clu_code, code_generated,fullname,username,  email, phone_number, identity_gender, identity_agama,  identity_tempatlahir, identity_tanggallahir, identity_statuspernikahan, identity_kewarganegaraan, identity_pekerjaan, status_keluarga,  image_photo
            } as UserPayload_change_data

            onSubmit(payload)
        }
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
    
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                        <h3 className="text-xl font-medium text-white pb-4">{Object.keys(data).length > 0? "Form Update User" : "Form Create User"}</h3>
    
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label className="label-form">Code Clu</label>
                                <input type="text" id="clu_code" className="input-form" disabled={true}
                                    {...register('clu_code', {
                                        required: 'clu_code is required',
                                        maxLength: {value: 50, message: 'clu_code is too long'}
                                    })}
                                />
                                <ErrorField errors={errors} name='clu_code' />
                            </div>
                            <div className="w-full">
                                <label className="label-form">Code Generate</label>
                                <input type="text" id="code_generated" className="input-form"   disabled={true}
                                    {...register('code_generated', {
                                        required: 'code_generated is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='code_generated' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="username" className="label-form">Username</label>
                                <input type="text" id="username" className="input-form" disabled={true}
                                    {...register('username', {
                                        required: 'username is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='username' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="fullname" className="label-form">Fullname</label>
                                <input type="text" id="fullname" className="input-form" placeholder="Full Name" 
                                    {...register('fullname', {
                                        required: 'Fullname is required',
                                        maxLength: {value: 50, message: 'Fullname is too long'}
                                    })}
                                />
                                <ErrorField errors={errors} name='fullname' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label className="label-form">[I] Tempat Lahir</label>
                                <input type="text" id="identity_tempatlahir" className="input-form" placeholder=''
                                    {...register('identity_tempatlahir', {
                                        required: 'identity_tempatlahir is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='identity_tempatlahir' />
                            </div>
                            <div className="flex space-x-4   items-center mt-3">
                                <div className="flex  flex-col">
                                <label className="label-form">[I] Tanggal Lahir</label>
                                    <div className="flex items-center" style={{ height: '70px', width: '300px', marginTop: '-10px'  }}>
                                        <input type="date" id="identity_tanggallahir" className="input-form" placeholder=''
                                            {...register('identity_tanggallahir', {
                                                required: 'identity_tanggallahir is required',
                                            })}
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                        </svg>
                                    </div>
                                    <ErrorField errors={errors} name='identity_tanggallahir' />
                                </div>
                                {/* <input id="identity_pekerjaan" type="text" autoComplete="identity_pekerjaan" className="text-input"
                                    /> */}
                            </div>
                            
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="identity_gender" className="label-form">Jenis Kelamin</label>
                                <select id="identity_gender" className="input-form" {...register('identity_gender', {required: 'identity_gender is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_JENISKELAMIN.map((item, key) =>
                                        <option 
                                            key={key} 
                                            value={item.name}>
                                                {item.name.toUpperCase()}
                                        </option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='role_id' />
                            </div>
                            <div className="w-full">
                                <label  className="label-form">Agama</label>
                                <select id="identity_agama" className="input-form" {...register('identity_agama', {required: 'identity_agama is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_AGAMA.map((item, key) =>
                                        <option 
                                            key={key} 
                                            value={item.name}>
                                                {item.name.toUpperCase()}
                                        </option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='role_id' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label  className="label-form">Status Di KK</label>
                                <select id="status_keluarga" className="input-form" {...register('status_keluarga', {required: 'status_keluarga is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_STATUS_KELUARGA.map((item, key) =>
                                        <option 
                                            key={key} 
                                            value={item.name}>
                                                {item.name.toUpperCase()}
                                        </option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='status_keluarga' />
                            </div>
                            <div className="w-full">
                                <label  className="label-form">Status Pernikahan</label>
                                <select id="identity_statuspernikahan" className="input-form" {...register('identity_statuspernikahan', {required: 'identity_statuspernikahan is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_STATUS_PERNIKAHAN.map((item, key) =>
                                        <option 
                                            key={key} 
                                            value={item.name}>
                                                {item.name.toUpperCase()}
                                        </option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='identity_statuspernikahan' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label className="label-form">Email</label>
                                <input id="email" type="text" className="input-form" placeholder=''
                                {...register("email", {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address."
                                    }
                                })} />
                                <ErrorField errors={errors} name='email' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="phone_number" className="label-form">Nomor HP</label>
                                <input type="text" id="phone_number" className="input-form" placeholder="" 
                                    {...register('phone_number', {
                                        required: 'phone_number is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='phone_number' />
                            </div>
                        </div>
                        
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label className="label-form">[I] Kewarga Negaraan</label>
                                <input type="text" id="identity_kewarganegaraan" className="input-form" placeholder=''
                                    {...register('identity_kewarganegaraan', {
                                        required: 'identity_kewarganegaraan is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='identity_kewarganegaraan' />
                            </div>
                            <div className="w-full">
                                <label className="label-form">[I] Pekerjaan</label>
                                <input type="text" id="identity_pekerjaan" className="input-form" placeholder=''
                                    {...register('identity_pekerjaan', {
                                        required: 'identity_pekerjaan is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='identity_pekerjaan' />
                            </div>
                            
                        </div>
    
                        {Object.keys(data).length===0 &&
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="password" className="label-form">Passsword</label>
                                    <input type="password" id="password" placeholder="••••••••" className="input-form" 
                                        {...register("password", {
                                                required: "Password is required.",
                                                minLength: { value: 5, message: "Password must exceed 4 characters."}
                                        })}
                                        />
                                    <ErrorField errors={errors} name='password' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="confirm_password" className="label-form">Confirm Password</label>
                                    <input type="password" id="confirm_password" className="input-form" placeholder="••••••••" 
                                        {...register("confirm_password", { required: "Confirm password is required." })} />
                                    <ErrorField errors={errors} name='confirm_password' />
                                </div>
                            </div>
                        }
    
                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center pt-4 space-x-4">
                            <button type='submit' className="btn-primary px-7">Save</button>
                            <button type='reset'  className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
}

export default UserForm;