import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserPayload } from '../../api/user-api';
import ErrorField from '../ErrorField';
import TableFull from '../../components/Table';


const LIST_ROLE = [
	{ id: 99, name: 'Super Admin' },
	{ id: 1, name: 'Administrator' }
]

interface UserImportExtend extends UserPayload {
    confirm_password: string
}

type Props = {
    jsonDataArray_show?: any,
    data?: any,
    onSubmit: (data: any) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const UserImport: React.FC<Props> = ({jsonDataArray_show, data, onSubmit, onClose }) => {
    const [filterData, setFilterData] = useState([])
    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Name</span>,
            Footer: 'Fullname',
            accessor: 'fullname',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Username',
            Footer: 'Username',
            accessor: 'username',
        },
        {
            Header: 'Status Keluarga',
            Footer: 'status_keluarga',
            accessor: 'status_keluarga'
        },
        {
            Header: 'Tempat Lahir',
            Footer: 'Tempat Lahir',
            accessor: 'identity_tempatlahir'
        },
        {
            Header: 'Tanggal Lahir',
            Footer: 'Tanggal Lahir',
            accessor: 'identity_tanggallahir'
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i>
                        <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        }
    ]
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });

        const setdefaultValue = useCallback ((obj: any) => {
            Object.keys(obj).map(key => {
                return setValue(key, obj[key], { shouldValidate: true })
            })
        }, [setValue])

        useEffect(() => {
            console.log(data)
            if(Object.keys(data).length !== 0){
                setdefaultValue(data)
            }
        }, [data, setdefaultValue])

        const handleAction = (rowData: any) => {
            // Perform the desired action for each row
            console.log('Action performed for row:', rowData);
            // Example: You can access rowData properties and perform actions accordingly
        };
        
        const onSubmitForm: SubmitHandler<UserImportExtend> = ({fullname, username, password, confirm_password, email, role_id}) => {
            if(Object.keys(data).length === 0){
                if(password !== confirm_password){
                    toast.warning("Password not match")
                    return
                }
            }

            const payload = {
                fullname, username, password, email, role_id
            } as UserPayload

            if(Object.keys(data).length !== 0){
                delete payload.password
            }

            onSubmit(payload)
        }
    
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
    
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    {/* <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8"> */}
                        
                        <div style={{ marginLeft: '10px', marginRight: '100px' }}>
                            <h3 className="text-xl font-medium text-white pb-4">{ "Form Import User" }</h3>
                            <table>
                                <thead>
                                    <tr>
                                        {/* Add an empty cell for the action button column */}
                                        <th></th>
                                        {/* Render table headers */}
                                        {jsonDataArray_show.length > 0 &&
                                            Object.keys(jsonDataArray_show[0]).map((key, index) => (
                                                <th key={index} style={{ color: 'white' }}>{key}</th>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {jsonDataArray_show.map((rowData: any, rowIndex: number) => (
                                        <tr key={rowIndex}>
                                            {/* Render table cells */}
                                            {Object.values(rowData).map((value: any, colIndex: number) => (
                                                <td key={colIndex} style={{ color: 'white' }}>{value}</td>
                                            ))}
                                            {/* Render action button */}
                                            {/* <td>
                                                <button onClick={() => handleAction(rowData)} style={{ color: 'white' }}>Action</button>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                        {/* <TableFull dataTable={filterData} columnTable={columns} /> */}
                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center pt-4 space-x-4" >
                            <button type='submit' onClick={onSubmit} className="btn-primary px-7">Save</button>
                            <button type='reset' className="btn-secondary"  onClick={onClose}>Cancel</button>
                        </div>
    
                    {/* </form> */}
                </div>
            </div>
        </>
}

export default UserImport;