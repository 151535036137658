import React, { useEffect , useState } from 'react';
import moment, { Moment } from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SubmitFunction, useNavigate } from 'react-router-dom';
import ErrorField from '../../components/ErrorField';
import Datepick from '../../components/Datepick';
import appLogo from '../../assets/img/applogo.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { Registerapi, RegisterPayload } from '../../api/common-api';
import { Clucode_check, clucode_check } from '../../api/common-api';
import { get_homenumber, gethomenumberpayload } from '../../api/common-api';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import { format } from 'date-fns';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import Calendar from 'react-calendar';

import { toast } from 'react-toastify';

type Props = {};

const LIST_STATUS = [
	{ id: 1, name: 'BELUM MENIKAH' },
	{ id: 2, name: 'SUDAH MENIKAH' },
	{ id: 3, name: 'CERAI HIDUP' },
	{ id: 4, name: 'CERAI MENINGGAL' }
]
const LIST_RT = [
	{ id: 1, name: '0' }
]
const LIST_RW = [
	{ id: 1, name: '0' }
]
const LIST_AGAMA = [
	{ id: 1, name: 'ISLAM' },
	{ id: 2, name: 'KRISTEN (PROTESTAN)' },
	{ id: 3, name: 'KATOLIK' },
	{ id: 4, name: 'HINDU' },
	{ id: 5, name: 'BUDHA' },
	{ id: 6, name: 'KONGHUCU' }
]

const LIST_STATUS_KELUARGA = [
    { id: 2, name: 'Suami' },
    { id: 3, name: 'Istri' },
    { id: 4, name: 'Anak' },
    { id: 5, name: 'Orang Tua' },
    { id: 6, name: 'Saudara' },
    { id: 7, name: 'Lain-lain' }
]

const LIST_ROLE = [
	{ id: 1, name: 'Warga' }
]
const LIST_GENDER = [
	{ id: 1, name: 'Laki-laki' },
	{ id: 2, name: 'Perempuan' }
]
const status_home_select = [
	{ id: 1, name: 'Tetap' },
	{ id: 2, name: 'Berjangka' }
]

interface DataItem {
    blok_name: string;
    clu_name: string;
}

interface DataItem_home {
    blok_name: string;
    number_home: string;
}


const SignUp: React.FC<Props> = () => 
{
    const [modeform_global, set_modeform_global]      = useState(false);

    const [selectedDate_str, setSelectedDate_str] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    
    const handleDateChanges = (value: string | Moment) => {
        if (typeof value === 'string') {
            setSelectedDate(new Date(value));
        } else {
            setSelectedDate(value.toDate());
            setSelectedDate_str(value.format('YYYY-MM-DD'));
        }
        console.log("Date [Str] "+ selectedDate_str + " [Date] " + selectedDate)
    };

    const [clu_code_generated, set_clucodegenerated]               = useState('');
    const [clu_code, set_clucode]               = useState('');
    const [blok_name, set_blok_name]            = useState('');
    const [number_home, set_number_home]        = useState('');
    const [data_rt, set_data_rt]                = useState('');
    const [data_rw, set_data_rw]                = useState('');
    const [statustinggal, set_statustinggal]                = useState('');
    const [data_roleid, set_data_roleid]        = useState('');
    const [statuskeluarga, set_statuskeluarga]        = useState('');
    const [data_blok, setdata_Blok]             = useState<DataItem[]>([]);
    const [data_number, setdata_Number]         = useState<DataItem_home[]>([]);
    const [modal_blok, showModal_blok]          = useState(false);
    const [modal_form, showModal_form]      = useState(false);
    const [modal_btnback_blok, set_modal_btnback_blok]      = useState(false);
    // const [modal_validrole, set_modal_modal_validrole]      = useState(false);
    const [disable_blok, set_disable_blok]      = useState(false);
    const [isHovered_check, setIsHovered_check] = useState(false);
    const [isHovered, setIsHovered]             = useState(false);
    const [isHovered_green, setIsHovered_green]             = useState(false);
    const [show_setidentity, setShow_content_identity]             = useState(false);
    const [payload_clucheck, set_payload_clucheck]         = useState<clucode_check>();
    const [payload_getnumberhome, set_payload_API_GETHOME] = useState<gethomenumberpayload>({
        clu_code: '',
        blok_name: '',
    });

    const navigate = useNavigate()

    const buttonStyle_green = {
        backgroundColor: isHovered_green ? '#4ADE80' : '#2EC862',
        color: 'white',
        transition: 'background-color 0.1s', // Add a smooth transition effect
        
        height: '40px', // Set the height of the button
        width: '300px' ,
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'White',
        },
      };
      const buttonStyle = {
            backgroundColor: isHovered ? 'grey' : 'red',
            color: 'white',
            transition: 'background-color 0.1s', // Add a smooth transition effect
            width: '300px' ,
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'White',
            },
          };
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<RegisterPayload>({ criteriaMode: "all" });
    useEffect(() => {
        localStorage.clear()
    }, [])

    const back_btn = () =>{
        navigate("/auth/sign-in");
    }
    
    const btn_show_next_identity = () =>{
        setShow_content_identity(true);
    }
    const btn_show_back_identity = () =>{
        setShow_content_identity(false);
    }
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_clucode(event.target.value)
        set_clucodegenerated(event.target.value)
        set_payload_clucheck({
            ...payload_clucheck,
            "clu_code": event.target.value
        });
    };
    const onCheck_clu = async () => {
        console.log("> onCheck_clu :", payload_clucheck?.clu_code ," | ", modal_blok);
        
        showModal_blok(false)
        console.log("> Clucode_check :",payload_clucheck )
        const res = await Clucode_check(payload_clucheck as clucode_check )
        console.log("< Clucode_check :", res)
        if(res.data)
        {
            if(res.data.code == 0)
            {
                if(res.data.data.rowCount > 0)
                {
                    console.log("DATA :", res.data)
                    console.log("DATA :", res.data.data.rows)
                    setdata_Blok(res.data.data.rows)
                    // set_modal_modal_validrole(true)
                    showModal_blok(true)
                    set_modal_btnback_blok(true)
                    set_modeform_global(true)
                    set_statuskeluarga("Kepala Keluarga")
                }
                else
                {
                    toast.error('['+res.request.status+'] Data Blok Tidak ditemukan : '+res.data.data.rowCount)
                }
            }
            else if(res.data.code == 1)
            {
                console.log("DATA :", res.data.data)
                console.log("Asal Setter :" + res.data.data.clu_code + " | " + res.data.data.blok_name + " | " + res.data.data.number_home + " | " + res.data.data.data_rt+ " | " + res.data.data.data_rw+ " | " + res.data.data.role_id )
            
                console.log("Setter :" + data_rt + " | " + data_rw + " | " + clu_code + " | " + blok_name+ " | " + number_home )
                
                set_clucode(res.data.data.clu_code)
                set_blok_name(res.data.data.blok_name)
                set_number_home(res.data.data.number_home)
                set_data_rt(res.data.data.data_rt)
                set_data_rw(res.data.data.data_rw)
                set_data_roleid(res.data.data.role_id)
                set_statustinggal(res.data.data.status_tinggal)
                
                set_modeform_global(false)
                showModal_form(true)
                console.log("Setter :" + data_rt + " | " + data_rw + " | " + clu_code + " | " + blok_name+ " | " + number_home )
            }
            else
            {
                toast.error('['+res.request.status+']Failed : '+res.data.message ,res.data.message)
            }
        }else{
            toast.error('Connection timeout..')
        }
        // try {
        //     const res = await Registerapi(formData as RegisterPayload );
        //     console.log("< onCheck_clu : ", res.data);
        // } catch (error) {
        //     console.error("Error:", error);
        //     toast.error('An error occurred. Please try again later.');
        // }
    };
    const onCheck_blok = async () => {
        console.log("> get_homenumber : " + clu_code + " | "+ blok_name );
        set_clucode(clu_code);
        set_blok_name(blok_name);
        setShow_content_identity(false);
        const newPayload: gethomenumberpayload = {
            clu_code: clu_code,
            blok_name: blok_name,
        };
        set_payload_API_GETHOME(newPayload);

        try {
            console.log("HIT API "+payload_getnumberhome.blok_name + payload_getnumberhome?.clu_code);
            const res = await get_homenumber(payload_getnumberhome as gethomenumberpayload);
            console.log("< get_homenumber :", res);

            if (res.data) {
                if (res.data.code === 0) {
                    if (res.data.data.rowCount > 0) {
                        console.log("DATA :", res.data.data.rows);
                        setdata_Number(res.data.data.rows)
                        showModal_form(true);
                        set_disable_blok(true);
                        set_modal_btnback_blok(false)
                        console.log("> Set Selected : "+ disable_blok);
                    } else {
                        toast.error('[' + res.request.status + '] Data Blok Tidak ditemukan : ' + res.data.data.rowCount);
                    }
                } else {
                    toast.error('[' + res.request.status + ']Failed : ' + res.data.message, res.data.message);
                }
            } else {
                toast.error('Connection timeout..');
            }
        } catch (error) {
            console.error("Error occurred while fetching data:", error);
        }
    };
    const blok_change = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value;
        set_blok_name(newValue);
        
        const newPayload: gethomenumberpayload = {
            clu_code: clu_code,
            blok_name: newValue,
        };
        set_payload_API_GETHOME(newPayload);
    };
    const number_change = ((event: React.ChangeEvent<HTMLSelectElement>) => {
        set_number_home(event.target.value);
        console.log("> number_change :" + number_home);
    });
    const onSubmitForm: SubmitHandler<RegisterPayload> = async (data) => {
        data.identity_tanggallahir = format(selectedDate, 'yyyy-MM-dd');
        data.image_photo = "" ;

        data.rt_account_level = "1" ;
        data.rt_sub_level_role = "1" ;
        data.rw_account_level = "1" ;
        data.rw_sub_level_role = "1" ;
        if(modeform_global)
        {
            const min = 10000; // Minimum 5-digit number
            const max = 99999; // Maximum 5-digit number
            const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
            data.code_generated =  ""+ data.clu_code + data.blok_name + data.number_home + data.username +"-"+randomNumber;
            data.kepala_kk = "1" ;
            data.status_user = "1" ;
            data.status_keluarga = statuskeluarga;
            
        }else{
            console.log(data_roleid);
            data.role_id = data_roleid;
            data.clu_code = ""+clu_code;
            data.code_generated =  ""+clu_code_generated;//data.clu_code;
            data.blok_name = ""+blok_name;
            data.number_home = ""+number_home;
            data.data_rt = ""+data_rt;
            data.data_rw = ""+data_rw;
            data.kepala_kk = "0" ;
            data.status_user = "2" ;
            data.status_tinggal = statustinggal;
        }

        
        data.identity_tempatlahir = "" + data.identity_tempatlahir.toUpperCase() ;
        data.identity_pekerjaan = "" + data.identity_pekerjaan.toUpperCase() ;
        data.identity_kewarganegaraan = "" + data.identity_kewarganegaraan.toUpperCase() ;

        console.log("> Register :",data )
        const res = await Registerapi(data as RegisterPayload )
        console.log("< Register :", res)
        if(res.data){
            if(res.data.code === 0){
                const data = res.data.data
                const dataUser = {
                    id: data.role_id,
                    username: data.username,
                    fullname: data.fullname,
                    email: data.email,
                    role_id: data.role_id,
                    last_login_on: data.last_login_on
                }

                localStorage.setItem('dataUser', JSON.stringify(dataUser))
                localStorage.setItem('authToken', data.token)
                toast.done(data.message)
                navigate('/auth/sign-in', {replace: true})
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error('Connection timeout..')
        }
    };
    return (
        <>
            <div className="h-full flex flex-col justify-center items-center space-y-8">
                <div className="flex items-start">
                    {/* Logo on the left */}
                    <LazyLoadImage effect='blur' src={appLogo} className='w-20 h-20' />

                    {/* Text on the right */}
                    <div className="ml-4">
                    <h1 className="text-2xl md:text-3xl font-extrabold">Sign Up to your account</h1>
                    <p className="mt-2">Clu Registration Form</p>
                    </div>
                </div>
                {!modal_form && ( 
                    <div>
                    <div className="flex space-x-4">
                        <div className="flex-1">
                            <div className="flex flex-col items-center"> {/* Menggunakan items-center untuk membuat rata tengah */}
                                <div className="flex flex-col items-center"> {/* Menggunakan items-center untuk membuat rata tengah */}
                                    <div className=" md:flex md:space-x-10 space-y-5 md:space-y-0 space-x-4 items-center justify-center">
                                        <label className="font-semibold mb-1" 
                                            style={{ width: '100px' }} htmlFor="clu_code">clucode</label>
                                        <input id="clu_code" type="text" autoComplete="clu_code"
                                            className="text-input"
                                            {...register("clu_code", {
                                                required: "clu_code is required."
                                            })}
                                            disabled={modal_blok}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            style={{ width: '300px' }}
                                        />
                                    </div>
                                    <ErrorField errors={errors} name='clu_code' />
                                    
                                    {!modal_blok && ( 
                                        
                                        <div className="flex flex-col items-center justify-center">
                                            <button className=" mt-4" onClick={onCheck_clu} style={buttonStyle_green} 
                                                onMouseEnter={() => setIsHovered_check(true)} onMouseLeave={() => setIsHovered_check(false)} >
                                                Check Code Clu
                                            </button>
                                            <button className=" mt-4" onClick={back_btn} style={buttonStyle}
                                                onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                                                Back To Login
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {modal_blok && ( 
                        <div className="flex space-x-4">
                        <div className="flex-1">
                            <div className="flex space-x-4 items-center justify-center" style={{ marginTop: '15px' }}>
                                <label className="font-semibold mb-1" 
                                    style={{ width: '100px' }} htmlFor="Role">Role</label>
                               
                                <select id="role" className="input-form SelectedRole" 
                                    {...register('role_id', {required: 'Role is required'})}
                                    style={{ width: '300px' }} 
                                    >
                                    <option value=''>- Select Role -</option>
                                    {LIST_ROLE.map((item, key) =>
                                        <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                    )}
                                </select>
                            </div>
                            <div className="flex flex-col items-center"> {/* Menggunakan items-center untuk membuat rata tengah */}
                                <div className="flex flex-col items-center"> {/* Menggunakan items-center untuk membuat rata tengah */}
                                    <div className="flex space-x-4 items-center justify-center" style={{ marginTop: '15px' }}>
                                        <label className="font-semibold mb-1" 
                                            style={{ width: '100px' }} htmlFor="blok_name">Blok Name</label>
                                        <select id="blok_name" className="input-form SelectedRole" value={blok_name} disabled={disable_blok}
                                            {...register('blok_name', {required: 'blok_name is required'})}
                                            // onChange={(event: React.ChangeEvent<HTMLSelectElement>) => blok_change(event )}
                                            onChange={(event) => blok_change(event)}
                                            style={{ width: '300px' }}
                                            >
                                            <option value=''>- Select Blok -</option>
                                            {data_blok.map((item, index) => (
                                                <option key={index} value={item.blok_name}>
                                                    {item.blok_name}
                                                </option>
                                        ))}
                                        </select>
                                    </div>
                                    <ErrorField errors={errors} name='blok_name' />
                                    
                                    {!modal_blok && ( 
                                        <div className="flex flex-col items-center justify-center">
                                            <button className=" mt-4" onClick={onCheck_clu} style={buttonStyle_green} 
                                                onMouseEnter={() => setIsHovered_check(true)} onMouseLeave={() => setIsHovered_check(false)} >
                                                Check Code Clu
                                            </button>
                                            <button className=" mt-4" onClick={back_btn} style={buttonStyle}
                                                onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                                                Back To Login
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    {/* {modal_validrole && ( 
                        <div className="flex-1">
                            <label className="font-semibold" htmlFor="role">Role</label>
                            <select id="role" className="input-form SelectedRole" {...register('role_id', {required: 'Role is required'})}>
                                <option value=''>- Select Role -</option>
                                {LIST_ROLE.map((item, key) =>
                                    <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                )}
                            </select>
                            <ErrorField errors={errors} name='role_id' />
                        </div>
                    )} */}
                    {modal_btnback_blok && ( 
                        <div>
                            <div className="flex flex-col items-center justify-center">
                                <button className=" mt-4" onClick={onCheck_blok} style={buttonStyle_green}
                                    onMouseEnter={() => setIsHovered_check(true)} onMouseLeave={() => setIsHovered_check(false)} >
                                    Check Number Home
                                </button>
                                <button className=" mt-4" onClick={back_btn} style={buttonStyle}
                                    onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                                    Back To Login
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                )}
                
                <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-7 px-3 md:px-0 w-11/12 md:w-7/12">
                    {modal_form && ( 
                        <div>
                            {!show_setidentity && ( 
                                <div>
                                    {modeform_global && ( 
                                        <div>
                                            <div className="flex space-x-4">
                                                <div className="flex-1">
                                                    <label className="font-semibold">RT</label>
                                                    <select id="add_rt" className="input-form SelectedRole" 
                                                    {...register('data_rt', {required: 'add_rt is required'})}>
                                                        <option value=''>- Select Role -</option>
                                                        {LIST_RT.map((item, key) =>
                                                            <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                                        )}
                                                    </select>
                                                    <ErrorField errors={errors} name='add_rt' />
                                                </div>
                                                <div className="flex-1">
                                                    <label className="font-semibold">RW</label>
                                                    <select id="add_rw" className="input-form SelectedRole" 
                                                    {...register('data_rw', {required: 'add_rw is required'})}>
                                                        <option value=''>- Select Role -</option>
                                                        {LIST_RW.map((item, key) =>
                                                            <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                                        )}
                                                    </select>
                                                    <ErrorField errors={errors} name='add_rw' />
                                                </div>
                                            </div>
                                            <div className="flex space-x-4">
                                                <div className="flex-1">
                                                    <label className="font-semibold" htmlFor="number_home">Blok Number</label>
                                                    <select id="number_home" className="input-form SelectedRole" value={number_home}
                                                        {...register('number_home', {required: 'number_home is required'})}
                                                        onChange={(event) => number_change(event)}
                                                        > 
                                                        <option value=''>- Select Role -</option>
                                                        
                                                        {data_number.map((item, index) => (
                                                            <option key={index} value={item.number_home}>
                                                                {item.number_home}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    
                                                    <ErrorField errors={errors} name='number_home' />
                                                </div>
                                                <div className="flex-1">
                                                    <label className="font-semibold" htmlFor="status_tinggal">Status Tinggal</label>
                                                    <select id="status_tinggal" className="input-form SelectedRole"
                                                        {...register('status_tinggal', {required: 'status_tinggal is required'})}
                                                        > 
                                                        <option value=''>- Select Role -</option>
                                                        
                                                        {status_home_select.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <ErrorField errors={errors} name='status_tinggal' />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!modeform_global && ( 
                                        <div>
                                            <div className="flex space-x-4">
                                                <div className="flex-1">
                                                    <label className="font-semibold">RT</label>
                                                    <input id="rt_noglobal" type="text" value={data_rt} disabled={!modeform_global} className="text-input"/>
                                                    <ErrorField errors={errors} name='rt' />
                                                </div>
                                                <div className="flex-1">
                                                    <label className="font-semibold">RW</label>
                                                    <input id="rw_noglobal" type="text" value={data_rw} disabled={!modeform_global} className="text-input"/>
                                                    <ErrorField errors={errors} name='rw' />
                                                </div>
                                            </div>
                                            <div className="flex space-x-4">
                                                <div className="flex-1">
                                                    <label className="font-semibold" >Blok Name</label>
                                                    <input id="rt_noglobal" type="text" value={blok_name} disabled={!modeform_global} className="text-input"/>
                                                    <ErrorField errors={errors} name='Blok Name' />
                                                </div>
                                                <div className="flex-1">
                                                    <label className="font-semibold" >Number Home</label>
                                                    <input id="rw_noglobal" type="text" value={number_home} disabled={!modeform_global}  className="text-input"/>
                                                    <ErrorField errors={errors} name='Number Home' />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex space-x-4">
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="username">Username</label>
                                            <input id="username" type="text" autoComplete="username" className="text-input"
                                                {...register("username", {
                                                required: "Username is required."
                                                })} />
                                            <ErrorField errors={errors} name='username' />
                                        </div>
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="password">Password</label>
                                            <input id="password" type="password" autoComplete="current-password" className="text-input"
                                                {...register("password", {
                                                required: "Password is required.",
                                                minLength: { value: 5, message: "Password must exceed 4 characters." }
                                                })} />
                                            <ErrorField errors={errors} name='password' />
                                        </div>
                                    </div>
                                    <div className="flex space-x-4">
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="fullname">fullname</label>
                                            <input id="fullname" type="text" autoComplete="fullname" className="text-input"
                                                {...register("fullname", {
                                                    required: "fullname is required."
                                                })} />
                                            <ErrorField errors={errors} name='fullname' />
                                        </div>
                                    </div>

                                    <div className="flex space-x-4">
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="email">Email</label>
                                            <input id="email" type="text" autoComplete="email" className="text-input"
                                                {...register("email", {
                                                    required: "email is required."
                                                })} />
                                            <ErrorField errors={errors} name='email' />
                                        </div>
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="phone_number">Whatsapp Number</label>
                                            <input id="phone_number" type="text" autoComplete="phone_number" className="text-input"
                                                {...register("phone_number", {
                                                    required: "phone_number is required."
                                                })} />
                                            <ErrorField errors={errors} name='phone_number' />
                                        </div>
                                    </div>
                                    
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className="btn-submit btn-margin" onClick={btn_show_next_identity} style={{ width: '300px' }}>
                                            Next [2/3]
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className="btn-submit" onClick={back_btn} style={buttonStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                            Back To Login
                                        </button>
                                    </div>
                                </div>
                            )}

                            {show_setidentity && ( 
                                <div>
                                    <div className="flex space-x-4   items-center mt-10">
                                        <div className="flex-1">
                                            <label className="font-semibold">[I] Tempat Lahir</label>
                                            <input id="identity_tempatlahir" type="text" autoComplete="identity_tempatlahir" className="text-input"
                                                {...register("identity_tempatlahir", {
                                                    required: "identity_tempatlahir is required."
                                                })} />
                                            <ErrorField errors={errors} name='identity_tempatlahir' />
                                        </div>
                                        <div className="flex-1">
                                            <label className="font-semibold">[I] Tanggal Lahir</label>
                                            <div className="flex items-center" style={{ height: '70px', width: '300px', marginTop: '-10px'  }}>
                                                    <Datetime 
                                                        closeOnSelect 
                                                        onChange={handleDateChanges} 
                                                        dateFormat="YYYY-MM-DD" 
                                                        value={selectedDate} 
                                                        timeFormat={false} // Hide the time
                                                        className="appearance-none shadow border rounded py-3 px-2 text-gray-darker"
                                                    />
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                </svg>
                                            </div>
                                            {/* <input id="identity_pekerjaan" type="text" autoComplete="identity_pekerjaan" className="text-input"
                                                /> */}
                                            <ErrorField errors={errors} name='identity_tanggallahir' />
                                        </div>
                                    </div>
                                    <div className="flex space-x-4">
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="identity_NIK">[I] NIK</label>
                                            <input id="identity_NIK" type="text" autoComplete="identity_NIK" className="text-input"
                                                {...register("identity_NIK", {
                                                    required: "identity_NIK is required."
                                                })} />
                                            <ErrorField errors={errors} name='identity_NIK' />
                                        </div>
                                        <div className="flex-1">
                                            <label className="font-semibold" htmlFor="identity_nokk">[I] NO KK</label>
                                            <input id="identity_nokk" type="text" autoComplete="identity_nokk" className="text-input"
                                                {...register("identity_nokk", {
                                                    required: "identity_nokk is required."
                                                })} />
                                            <ErrorField errors={errors} name='identity_nokk' />
                                        </div>
                                    </div>
                                    <div className="flex space-x-4">
                                        <div className="flex-1">
                                            <label className="font-semibold" >[I] Gender</label>
                                            <select id="identity_gender" className="input-form SelectedRole" 
                                            {...register('identity_gender', {required: 'identity_gender is required'})}>
                                                <option value=''>- Select Role -</option>
                                                {LIST_GENDER.map((item, key) =>
                                                    <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                                )}
                                            </select>
                                            <ErrorField errors={errors} name='identity_gender' />
                                        </div>
                                        <div className="flex-1">
                                            <label className="font-semibold">[I] Status Perkawinan</label>
                                            <select id="identity_statuspernikahan" className="input-form SelectedRole" 
                                            {...register('identity_statuspernikahan', {required: 'identity_statuspernikahan is required'})}>
                                                <option value=''>- Select Role -</option>
                                                {LIST_STATUS.map((item, key) =>
                                                    <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                                )}
                                            </select>
                                            <ErrorField errors={errors} name='identity_statuspernikahan' />
                                        </div>
                                                                                
                                    </div>
                                    <div className="flex space-x-4">
                                        <div className="flex-1">
                                            <label className="font-semibold">[I] Agama</label>
                                            <select id="identity_agama" className="input-form SelectedRole" 
                                            {...register('identity_agama', {required: 'identity_agama is required'})}>
                                                <option value=''>- Select Role -</option>
                                                {LIST_AGAMA.map((item, key) =>
                                                    <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                                )}
                                            </select>
                                            <ErrorField errors={errors} name='identity_agama' />
                                        </div>
                                        <div className="flex-1">
                                            <label className="font-semibold">[I] Kewarganegaraan</label>
                                            <input id="identity_kewarganegaraan" type="text" autoComplete="identity_kewarganegaraan" className="text-input"
                                                {...register("identity_kewarganegaraan", {
                                                    required: "identity_kewarganegaraan is required."
                                                })} />
                                            <ErrorField errors={errors} name='identity_kewarganegaraan' />
                                        </div>
                                    </div>
                                    <div className="flex space-x-4">
                                        {modeform_global && ( 
                                            <div className="flex-1">
                                            <label className="font-semibold">Status keluarga</label>
                                            <input id="status_keluarga" type="text" autoComplete="identity_pekerjaan" className="text-input" value={statuskeluarga} />
                                            </div>
                                        )}
                                        {!modeform_global && ( 
                                            <div className="flex-1">
                                                <label className="font-semibold">[I] Status Keluarga</label>
                                                <select id="status_keluarga" className="input-form SelectedRole" 
                                                {...register('status_keluarga', {required: 'status_keluarga is required'})}>
                                                    <option value=''>- Select Role -</option>
                                                    {LIST_STATUS_KELUARGA.map((item, key) =>
                                                        <option key={key} value={item.name}>{item.name.toUpperCase()}</option>
                                                    )}
                                                </select>
                                                <ErrorField errors={errors} name='status_keluarga' />
                                            </div>
                                        )}
                                        <div className="flex-1">
                                            <label className="font-semibold">[I] Pekerjaan</label>
                                            <input id="identity_pekerjaan" type="text" autoComplete="identity_pekerjaan" className="text-input"
                                                {...register("identity_pekerjaan", {
                                                    required: "identity_pekerjaan is required."
                                                })} />
                                            <ErrorField errors={errors} name='identity_pekerjaan' />
                                        </div>
                                    </div>
                                    <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <button className="btn-submit" onClick={btn_show_back_identity} style={buttonStyle_green} onMouseEnter={() => setIsHovered_green(true)} onMouseLeave={() => setIsHovered_green(false)}>
                                            Back [2/3]
                                        </button>
                                        <button type='submit' className="btn-submit btn-margin" style={{ width: '300px' }}>
                                            Regist [3/3]
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className="btn-submit" onClick={back_btn} style={buttonStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                            Back To Login
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </form>

                <div className='font-medium text-sm flex items-center justify-center text-dark'>
                    © Yot Project - clu v1.0.2[BetaFrontend]
                </div>
            </div>
        </>
    );
}

export default SignUp;