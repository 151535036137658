import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import ErrorField from '../../components/ErrorField';
import appLogo from '../../assets/img/applogo.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { Registerapi, RegisterPayload_CLU } from '../../api/common-api';
import { toast } from 'react-toastify';

type Props = {};

const LIST_ROLE = [
	{ id: 1, name: 'Warga' }
]
const SignUpClu: React.FC<Props> = () => {

    const [isReadey, setisReadey] = useState(false);
    const navigate = useNavigate()

    const buttonStyle = {
        backgroundColor: 'red',
        color: 'white',
        transition: 'background-color 0.3s', // Add a smooth transition effect
        marginTop: '20px', // Set the margin-top value
        
        ':hover': {
          backgroundColor: 'White',
        },
      };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<RegisterPayload_CLU>({ criteriaMode: "all" });

    useEffect(() => {
        localStorage.clear()
    }, [])

    const back_btn = () =>{
        navigate("/auth/sign-in");
    }
    
    const onSubmitForm: SubmitHandler<RegisterPayload_CLU> = async (data) => {
        data.image_photo = "" ;
        data.status_user = "-1" ;
        console.log("> Register :",data )
        // const res = await Registerapi(data as RegisterPayload_CLU )
        // console.log("< Register :", res)
        // if(res.data){
        //     if(res.data.code === 0){
        //         const data = res.data.data
        //         const dataUser = {
        //             id: data.role_id,
        //             username: data.username,
        //             fullname: data.fullname,
        //             email: data.email,
        //             role_id: data.role_id,
        //             last_login_on: data.last_login_on
        //         }

        //         localStorage.setItem('dataUser', JSON.stringify(dataUser))
        //         localStorage.setItem('authToken', data.token)
        //         toast.done(data.message)
        //         navigate('/auth/sign-in', {replace: true})
        //     }else{
        //         toast.error(res.data.message)
        //     }
        // }else{
        //     toast.error('Connection timeout..')
        // }
    }


    return (
        <>
            <div className="h-full flex flex-col justify-center items-center space-y-8">
                <div className="flex items-start">
                    {/* Logo on the left */}
                    <LazyLoadImage effect='blur' src={appLogo} className='w-20 h-20' />

                    {/* Text on the right */}
                    <div className="ml-4">
                    <h1 className="text-2xl md:text-3xl font-extrabold">Sign Up to your Clu</h1>
                    <p className="mt-2">Registration first clu</p>
                    </div>
                </div>
                {/* <LazyLoadImage effect='blur' src={appLogo} className='w-20 h-20 mx-auto' />

                <div className='text-center'>
                    <h1 className="text-2xl md:text-3xl font-extrabold">Sign Up to your account</h1>
                    <p className="mt-5">Clu Registration Form</p>
                </div> */}
                {!isReadey && (
                    <div>
                        <label className="font-semibold mb-1" htmlFor="label">not ready Please Contact Administrator</label>
                        <button onClick={back_btn} className="btn-submit " style={buttonStyle }>
                            Back
                        </button>
                    </div>
                )}
                
                {isReadey && (
                    <div>
                        <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-7 px-3 md:px-0 w-11/12 md:w-7/12">
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                        <div className="flex flex-col">
                                        <label className="font-semibold mb-1" htmlFor="clu_code">clucode</label>
                                        <input id="clu_code" type="text" autoComplete="clu_code" className="text-input"
                                            {...register("clu_code", {
                                            required: "clu_code is required."
                                            })} />
                                        </div>
                                        <ErrorField errors={errors} name='clu_code' />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex flex-col">
                                        <label className="font-semibold mb-1" htmlFor="clu_name">Clu Name</label>
                                        <input id="clu_name" type="text" autoComplete="clu_name" className="text-input"
                                            {...register("clu_name", {
                                            required: "clu_name is required."
                                            })} />
                                        </div>
                                        <ErrorField errors={errors} name='clu_name' />
                                    </div>
                                </div>
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                        <div className="flex flex-col">
                                        <label className="font-semibold mb-1" htmlFor="clu_address">clu address</label>
                                        <input id="clu_address" type="text" autoComplete="clu_address" className="text-input"
                                            {...register("clu_address", {
                                            required: "clu_address is required."
                                            })} />
                                        </div>
                                        <ErrorField errors={errors} name='clu_address' />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex flex-col">
                                        <label className="font-semibold mb-1" htmlFor="clu_post">Clu Post</label>
                                        <input id="clu_post" type="text" autoComplete="clu_post" className="text-input"
                                            {...register("clu_post", {
                                            required: "clu_post is required."
                                            })} />
                                        </div>
                                        <ErrorField errors={errors} name='clu_post' />
                                    </div>
                                </div>
                                
                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label className="font-semibold" htmlFor="username">Username</label>
                                        <input id="username" type="text" autoComplete="username" className="text-input"
                                            {...register("username", {
                                            required: "Username is required."
                                            })} />
                                        <ErrorField errors={errors} name='username' />
                                    </div>
                                    <div className="flex-1">
                                        <label className="font-semibold" htmlFor="password">Password</label>
                                        <input id="password" type="password" autoComplete="current-password" className="text-input"
                                            {...register("password", {
                                            required: "Password is required.",
                                            minLength: { value: 5, message: "Password must exceed 4 characters." }
                                            })} />
                                        <ErrorField errors={errors} name='password' />
                                    </div>
                                </div>
                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label className="font-semibold" htmlFor="fullname">fullname</label>
                                        <input id="fullname" type="text" autoComplete="fullname" className="text-input"
                                            {...register("fullname", {
                                                required: "fullname is required."
                                            })} />
                                        <ErrorField errors={errors} name='fullname' />
                                    </div>

                                    <div className="flex-1">
                                        <label className="font-semibold" htmlFor="email">Role</label>
                                        <select id="role" className="input-form SelectedRole" {...register('role_id', {required: 'Role is required'})}>
                                            <option value=''>- Select Role -</option>
                                            {LIST_ROLE.map((item, key) =>
                                                <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                            )}
                                        </select>
                                        <ErrorField errors={errors} name='role_id' />
                                    </div>
                                </div>

                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label className="font-semibold" htmlFor="email">email</label>
                                        <input id="email" type="text" autoComplete="email" className="text-input"
                                            {...register("email", {
                                                required: "email is required."
                                            })} />
                                        <ErrorField errors={errors} name='email' />
                                    </div>
                                    <div className="flex-1">
                                        <label className="font-semibold" htmlFor="phone_number">nomor HP</label>
                                        <input id="phone_number" type="text" autoComplete="phone_number" className="text-input"
                                            {...register("phone_number", {
                                                required: "phone_number is required."
                                            })} />
                                        <ErrorField errors={errors} name='phone_number' />
                                    </div>
                                </div>
                                <button type='submit' className="btn-submit"  >
                                    Regist
                                </button>
                                <button onClick={back_btn} className="btn-submit " style={buttonStyle}>
                                    Back
                                </button>
                        </form>
                    </div>
                )}
                

                <div className='font-medium text-sm flex items-center justify-center text-dark'>
                    © Yot Project - clu v1.0.2[BetaFrontend]
                </div>
            </div>
        </>
    );
}
export default SignUpClu;