import MainDashboard from "./pages/dashboard/MainDashboard"
import RiwayatIPL from "./pages/dashboard/RiwayatIPL"
import Signup from "./pages/auth/SignUp"
import SignupClu from "./pages/auth/SignUp_clu"
import Login from "./pages/auth/Login"
import User from "./pages/dashboard/User"
import User_myprofile from "./pages/dashboard/User_myprofile"
import Category from "./pages/dashboard/Category"
import Product from "./pages/dashboard/Product"
import HomeContent from "./pages/dashboard/HomeContent"
import AboutUs from "./pages/dashboard/AboutUs"
import ContactUs from "./pages/dashboard/ContactUs"

import Mrt_1_manage_warga from "./pages/dashboard/management_rt/Mrt_1_manage_warga"
import Mrt_2_Pengurus_RT from "./pages/dashboard/management_rt/Mrt_2_Pengurus_RT"
import Mrt_3_bendahara_manage_ipl from "./pages/dashboard/management_rt/Mrt_3_bendahara_manage_ipl"
import Mrt_3_bendahara_2_report_ipl from "./pages/dashboard/management_rt/Mrt_3_bendahara_2_report_ipl"
import Mrt_3_bendahara_3_kas from "./pages/dashboard/management_rt/Mrt_3_bendahara_3_kas"
import Mrt_4_seksisosial from "./pages/dashboard/management_rt/Mrt_4_seksisosial"

import Trt_1_bendahara_1_report_ipl from "./pages/dashboard/transparansi_rt/Trt_1_bendahara_1_report_ipl"

export const AdminRoutes = [
    { // UMUM
        name_var: 'Dashboard',
        url_var: 'dashboard',
        icon_var: 'ri-home-line',
        only_admin: false,
        only_show: true,
        pengurus_rt: false,
        rt_account_level: 1,
        type_role_rt: 1,
        type_role_rw: 1,
    },
    {
        name_var: 'Riwayat IPL',
        url_var: 'riwayatipl',
        icon_var: 'ri-wallet-line',
        only_admin: false,
        only_show: true,
        pengurus_rt: false,
        rt_account_level: 1,
        type_role_rt: 1,
        type_role_rw: 1,
    },
    // {
    //     name_var: 'Olahraga',
    //     url_var: '#',
    //     icon_var: 'ri-boxing-line',
    //     only_admin: false,
    //     only_show: true,
    //     pengurus_rt: false,
    //     rt_account_level: 1,
    //     type_role_rt: 1,
    //     type_role_rw: 1,
    // },
    // {
    //     name_var: 'Ronda',
    //     url_var: '#',
    //     icon_var: 'ri-shield-user-fill',
    //     only_admin: false,
    //     only_show: true,
    //     pengurus_rt: false,
    //     rt_account_level: 1,
    //     type_role_rt: 1,
    //     type_role_rw: 1,
    // },
    // {
    //     name_var: 'Humas',
    //     url_var: '#',
    //     icon_var: 'ri-discuss-line',
    //     only_admin: false,
    //     only_show: true,
    //     pengurus_rt: false,
    //     rt_account_level: 1,
    //     type_role_rt: 1,
    //     type_role_rw: 1,
    // },
    {   // Management Warga RT
        name_var: 'Management Warga RT',
        url_var: '#',
        icon_var: 'ri-file-chart-line',
        only_admin: false,
        only_show: false,
        pengurus_rt: true,
        type_role_rt: 1,
        type_role_rw: 1,
        children: [
            {
                name_var: 'Management Warga',
                url_var: 'm_1_manage_warga',
                icon_var: 'ri-home-line',
                type_role_rt: 9,
                type_role_rw: 1
            },
            // {
            //     name_var: 'Pengurus RT',
            //     url_var: 'm_2_Pengurus_RT',
            //     icon_var: 'ri-home-line',
            //     type_role_rt: 9,
            //     type_role_rw: 1
            // }//,
            // {
            //     name_var: 'Inventaris RT',
            //     url_var: '',
            //     icon_var: 'ri-home-line',
            //     type_role_rt: 1,
            //     type_role_rw: 1
            // },
            {
                name_var: 'Bendahara - Manage IPL',
                url_var: 'm_3_Bendahara_1_manage_ipl',
                icon_var: 'ri-home-line',
                type_role_rt: 2,
                type_role_rw: 1
            },
            {
                name_var: 'Bendahara - Report IPL',
                url_var: 'm_3_Bendahara_2_report_ipl',
                icon_var: 'ri-home-line',
                type_role_rt: 2,
                type_role_rw: 1
            },
            {
                name_var: 'Bendahara - KAS',
                url_var: 'm_3_Bendahara_3_kas',
                icon_var: 'ri-home-line',
                type_role_rt: 2,
                type_role_rw: 1
            }
            // {
            //     name_var: 'Sekretaris',
            //     url_var: '',
            //     icon_var: 'ri-home-line',
            //     type_role_rt: 3,
            //     type_role_rw: 1
            // },
            // {
            //     name_var: 'Keamanan',
            //     url_var: '',
            //     icon_var: 'ri-home-line',
            //     type_role_rt: 4,
            //     type_role_rw: 1
            // },
            // {
            //     name_var: 'Seksi Olahraga',
            //     url_var: '',
            //     icon_var: 'ri-home-line',
            //     type_role_rt: 5,
            //     type_role_rw: 1
            // },
            // {
            //     name_var: 'Seksi Sosial',
            //     url_var: '',
            //     icon_var: 'ri-home-line',
            //     type_role_rt: 6,
            //     type_role_rw: 1
            // }
        ]
    },
    // {   // Management RW
    //     name_var: 'Management RW',
    //     url_var: 'MWRW',
    //     icon_var: 'ri-file-chart-line',
    //     only_admin: false,
    //     only_show: false,
    //     pengurus_rt: false,
    //     pengurus_rw: true,
    //     type_role_rt: 1,
    //     type_role_rw: 2,
    //     children: [
    //         {
    //             name_var: 'Inventaris RW',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 1,
    //             type_role_rt: 1
    //         },
    //         {
    //             name_var: 'Management Warga',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 9,
    //             type_role_rt: 1
    //         },
    //         {
    //             name_var: 'Document Sekretaris',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 2,
    //             type_role_rt: 1
    //         },
    //         {
    //             name_var: 'Report Payment IPL',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 3,
    //             type_role_rt: 1
    //         },
    //         {
    //             name_var: 'Report Ronda',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 5,
    //             type_role_rt: 1
    //         },
    //         {
    //             name_var: 'Report Dana Sosial',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 6,
    //             type_role_rt: 1
    //         },
    //         {
    //             name_var: 'Report Dana Olahraga',
    //             url_var: '',
    //             icon_var: 'ri-home-line',
    //             type_role_rw: 7,
    //             type_role_rt: 1
    //         }
    //     ]
    // },
    // {
    //     name_var: 'Home Manage',
    //     url_var: 'HM',
    //     icon_var: 'ri-home-line',
    //     only_admin: true,
    //     only_show: false,
    //     pengurus_rt: false,
    //     pengurus_rw: false,
    //     type_role_rt: 1,
    //     type_role_rw: 1,
    //     children: [
    //         {
    //             name_var: 'Home Content',
    //             url_var: 'home-content',
    //             icon_var: 'ri-home-line',
    //             type_role_rt: 1,
    //             type_role_rw: 1,
    //         }
    //     ]
    // },
    // {
    //     name_var: 'Product Management',
    //     url_var: 'PM',
    //     icon_var: 'ri-home-line',
    //     only_admin: false,
    //     only_show: false,
    //     pengurus_rt: false,
    //     pengurus_rw: false,
    //     type_role_rt: 1,
    //     type_role_rw: 1,
    //     children:
    //     [
    //         {
    //             name_var: 'Product Category',
    //             url_var: 'product-category',
    //             icon_var: 'ri-home-line',
    //             type_role: 1,
    //             type_role_rt: 1,
    //             type_role_rw: 1,
    //         },
    //         {
    //             name_var: 'Product post',
    //             url_var: 'product-catalog',
    //             icon_var: 'ri-home-line',
    //             type_role: 1,
    //             type_role_rt: 1,
    //             type_role_rw: 1,
    //         }
    //     ]
    // },
    // {
    //     name_var: 'Clu Management',
    //     url_var: 'CM',
    //     icon_var: 'ri-home-line',
    //     only_admin: false,
    //     only_show: false,
    //     pengurus_rt: false,
    //     pengurus_rw: false,
    //     type_role_rt: 1,
    //     type_role_rw: 1,
    //     children:
    //     [
    //         {
    //             name_var: 'About Us',
    //             url_var: 'about-us',
    //             icon_var: 'ri-home-line',                
    //             type_role_rt: 9,
    //             type_role_rw: 1
    //         },
    //         {
    //             name_var: 'Contact Us',
    //             url_var: 'contact-us',
    //             icon_var: 'ri-home-line',
    //             type_role_rt: 9,
    //             type_role_rw: 1
    //         }
    //     ]
    // },
]

export const dashboardRoutes = [
    {
        path: 'dashboard',
        component: <MainDashboard />
    },
    {
        path: 'riwayatIPL',
        component: <RiwayatIPL />
    },
    {
        path : "home-content",
        component: <HomeContent />
    },
    {
        path : "product-category",
        component: <Category />
    },
    {
        path : "about-us",
        component: <AboutUs />
    },
    {
        path : "contact-us",
        component: <ContactUs />
    },
    {
        path : "product-catalog",
        component: <Product />
    },
    {
        path : "user-management",
        component: <User />
    },
    {
        path : "usermyprofile-management",
        component: <User_myprofile />
    },
    {
        path : "m_1_manage_warga",
        component: <Mrt_1_manage_warga />
    },    
    {
        path : "m_2_Pengurus_RT",
        component: <Mrt_2_Pengurus_RT />
    },    
    {
        path : "m_3_Bendahara_1_manage_ipl",
        component: <Mrt_3_bendahara_manage_ipl />
    },     
    {
        path : "m_3_Bendahara_2_report_ipl",
        component: <Mrt_3_bendahara_2_report_ipl />
    }, 
    {
        path : "m_3_Bendahara_3_kas",
        component: <Mrt_3_bendahara_3_kas />
    }, 
    {
        path : "m_4_seksisosial",
        component: <Mrt_4_seksisosial />
    },
    {
        path : "t_1_b_ipl",
        component: <Trt_1_bendahara_1_report_ipl />
    }
    
    // USER ROUTE
]


export const AuthRoutes = [
    {
        path: 'sign-in',
        component: <Login />
    }
    ,
    {
        path: 'sign-up',
        component: <Signup />
    }
    ,
    {
        path: 'sign-up-clu',
        component: <SignupClu />
    }
    // {
    //     path: 'otp-validate',
    //     component: <OtpPage />
    // },
]