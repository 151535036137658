import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableFull from '../../components/Table';
import SearchField from '../../components/SearchField';
import { trx_updatepayment} from '../../api/user-api';
import Payment_form_pembayaran from '../../components/modals/payment_form_pembayaran_manual';
import PaymentAPI, { getmasterfare, trx_updatepayment_api, payload_riwayatPenarikan ,formCreatePayment} from '../../api/payment_api';
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';

type Props = {};

const MainDashboard: React.FC<Props> = () => {
    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null
    const [filterData, setFilterData] = useState<any[]>([]);

    const [dataPayment, setDataPayment] = useState<{ [key: string]: any }[]>([]);
    const jsonDataArray = [
        {
            no: 1,
            payment_type: 'IPL',
            payment_var: 'Bulan Maret',
            time_call_payment: '7 Maret 2024',
            by_call_payment: 'Sekretaris',
        },
        {
            no: 2,
            payment_type: 'OLAHRAGA',
            payment_var: 'Bulutangkis Maret',
            time_call_payment: '7 Maret 2024',
            by_call_payment: 'Seksi Olah Raga',
        }
    ];
    
    const handlepayment =  () => {
    }

    const fetchUser = useCallback( async () => {
        data_payload_riwayatPenarikan.username = user.username;
        data_payload_riwayatPenarikan.code_generated = user.code_generated;
        data_payload_riwayatPenarikan.filterbystatus_int = 1;
        data_payload_riwayatPenarikan.status_int = 0;
        data_payload_riwayatPenarikan.description_by_create = "";
        const res = await PaymentAPI.trx_getriwayat(data_payload_riwayatPenarikan);
        console.log('trx_getriwayat : ',res)
        set_show_riwayat(false)
        if(res.data){
            if(res.data.code === 0){
                if(res.data.data.length > 0)
                {
                    set_show_riwayat(true)
                }
                set_check_riwayat_ipl(res.data.data);
                set_check_riwayat_ipl_filter(res.data.data);
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }
        else{
            toast.error(res.message)
        }
        // console.log('check : ', jsonDataArray.length, dataPayment.length);
        // setDataPayment(jsonDataArray);
        // if(dataPayment.length)
        // {
        //     setDataPayment(jsonDataArray);
        // }
        // console.log('Effects' );
        // setFilterData(dataPayment);
        // console.log('check  after: ', jsonDataArray.length, dataPayment.length);
    }, [])
    
    useEffect(() => {
        // console.log('Effect');
        fetchUser()
    }, [fetchUser]); // Run once on component mount


    const navigate = useNavigate()
    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataPayment]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataPayment)
        }
    }
    const handleMyprofile= async () => {
        navigate('/dashboard/usermyprofile-management', {replace: true})
    }
    const downloadtemplate = () => {
        console.log('Download data:');
    }
    const payment_accept = () => {
        toast.success('Accept')
    }
    const payment_reject = () => {
        toast.success('Reject')
    }
    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Tipe Pembayaran</span>,
            Footer: 'payment_type',
            accessor: 'payment_type',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Pembayaran</span>,
            Footer: 'payment_var',
            accessor: 'payment_var',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Waktu Penagihan</span>,
            Footer: 'time_call_payment',
            accessor: 'time_call_payment',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Request</span>,
            Footer: 'by_call_payment',
            accessor: 'by_call_payment',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <i className="ri-refund-line black hover:text-green-400 cursor-pointer"  onClick={() =>handlepayment()}></i>
                        <i className="ri-edit-box-line black hover:text-green-400 cursor-pointer"      onClick={() =>handlepayment()}></i>
                    </div>
                )

                return <></>
            }
        }
    ]

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const [data_payload_riwayatPenarikan, set_payload_riwayatPenarikan] = useState<payload_riwayatPenarikan>({})
    
    // const fetchData = useCallback( async () => {
        
    // }, [navigate])

    const [payloadgetmasterfare, set_getmasterfare] = useState<getmasterfare>({})
    const [data_check_riwayat_ipl_filter, set_check_riwayat_ipl_filter] = useState([])
    const [data_check_riwayat_ipl, set_check_riwayat_ipl] = useState([])
    const [show_riwayat, set_show_riwayat] = useState(false)

    const [selectedformFormpembayaran, setFormpembayaran] = useState<formCreatePayment>({})
    const [modal_pembayaran, showModal_pembayaran] = useState(false)

    const handleReceiveDataForm_payment = async (data: trx_updatepayment_api) => {
        console.log('handleReceiveDataForm_payment : ',data)

        if(!data.images)
        {
            toast.error("IMAGE NULL")
          return;  
        }
        const formData = new FormData();

        formData.append('clu_code', user.clu_code);
        formData.append('code_generated', data.code_generated);
        formData.append('desc_payment', data.notes_payment);
        formData.append('edit_by_var', data.edit_by_var);
        formData.append('images', data.images);
        formData.append('mode_payment', data.mode_payment);
        formData.append('nominal_payment', data.nominal_payment);
        formData.append('status_int', data.status_int);
        formData.append('status_after_int', data.status_after_int);
        formData.append('description_by_create', data.description_by_create);
        formData.append('dir', data.dir);
        formData.append('type_payment_code_var', data.type_payment_code_var);
        
        const res = await PaymentAPI.trx_payment(formData)

        //console.log('< trx_payment :', res.data)
        if(res.data){
            if(res.data.code == 0){
                toast.success('Payment : ' + res.data.message)
                resetForm()
                fetchUser()
            } else if(res.data.code == 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error('Payment : ' + res.message)
        }
    }

    const resetForm = () => {
        showModal_pembayaran(false)
    }
    const handle_ui_riwayatIPL =  (selectedData: formCreatePayment) => {
        setFormpembayaran(selectedData)
        showModal_pembayaran(true)
    }
    

    const columns_master_riwayatIPL = [
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                    <div className="flex items-center cursor-pointer" onClick={() => handle_ui_riwayatIPL(data)}>
                        <i className="ri-edit-box-line text-primary hover:text-green-400"></i>
                        <i className="ml-2 text-sm  text-primary hover:text-green-400">Detail</i>
                    </div> 
                        {/* <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_riwayatIPL(data)}></i> */}
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        },
        {
            Header: () => <span className='p-4'>Status</span>,
            Footer: 'status_desc',
            accessor: 'status_desc',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Payment</span>,
            Footer: 'description_by_create',
            accessor: 'description_by_create',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nominal</span>,
            Footer: 'nominal_int',
            accessor: 'nominal_int',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Clu Code</span>,
            Footer: 'clu_code',
            accessor: 'clu_code',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Blok Name</span>,
            Footer: 'blok_name',
            accessor: 'blok_name',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'number_home',
            accessor: 'number_home',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nama</span>,
            Footer: 'nama_kepala_keluarga',
            accessor: 'nama_kepala_keluarga',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        }
    ]
    const handleSearch_riwayatipl = (event: any) => {
        event.preventDefault()

        const newData = [...data_check_riwayat_ipl_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.description_by_create?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.nama_kepala_keluarga?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.opr_subject_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            set_check_riwayat_ipl_filter(filtered)
        }else{
            set_check_riwayat_ipl_filter(data_check_riwayat_ipl)
        }
    }
    return (
        <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
            {/* <h1 className='text-base font-semibold'>Dashboard</h1> */}
            <div className="border-2 border-dark rounded-lg mt-5">
                <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                    <p>Hai <span className="font-semibold">{user.fullname || user.username}</span> !</p>
                </div>
                {user.status_user >= 0 ? (
                    <div className="bg-white px-5 py-6 rounded-bl-lg rounded-br-lg flex flex-col md:flex-row justify-between">
                        <div>
                            <p>Selamat datang kembali! </p>
                            <p>Code : <span >{user.code_generated}</span></p>
                        </div>
                        <div className='mt-5 md:mt-0'>
                            <p>Waktu Login (sebelumnya):</p>
                            <p>{moment(user.last_login_on).format('yyyy-MM-DD HH:mm:ss')}</p>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center pt-8 space-x-4">
                        <p className='text-xl font-semibold text-red-500 ml-5 '>Data Belum Lengkap, Mohon Lengkapi Data Diri : <span >{user.username}</span></p>
                        <button type='reset' className="btn-secondary mb-5 ml-5" onClick={() => handleMyprofile()}>Lengkapi Data</button>
                    </div>
                )}
            </div>
            
            {user.status_user >= 0 ? (
                <div>
                    { show_riwayat ? (
                        <div className="border-2 border-dark rounded-lg mt-5">
                            <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                                <h1 className='font-bold'>PEMBAYARAN</h1>
                            </div>
                            <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                                <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                                    <div className="flex-1" style={{ color: 'black' }}>
                                        <SearchField placeholder='Search data...' onChange={handleSearch_riwayatipl} />
                                    </div>
                                    <div className="flex justify-end" style={{ color: 'black' }}>
                                        <div className="flex" style={{ marginRight: '10px' }}>
                                        </div>
                                        <div className="flex" style={{ marginRight: '10px' }}>
                                            {/* <button type='reset' className="btn-secondary" onClick={create_payment(create_payment)}>GENERATE REPORT</button> */}
                                        </div>
                                    </div>
                                </div>
                                <TableFull dataTable={data_check_riwayat_ipl_filter} columnTable={columns_master_riwayatIPL} />
                            </div>
                        </div>
                        ):null
                    }
                    
                </div>
            ) : (
                null
            )}
            
            {modal_pembayaran && <Payment_form_pembayaran isEditable={true} data={selectedformFormpembayaran}  onClose={resetForm}  onReject={payment_reject} onAccept={payment_accept}  onSubmit={handleReceiveDataForm_payment} />}
      
        </div>
    )
}

export default MainDashboard;