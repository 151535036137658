// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, deleteUser, getAllUser_byblok, updateUser, UserPayload,  UserPayload_byblok } from '../../../api/user-api'; //getAllUser,getAllUser_byaddress, 
import SearchField from '../../../components/SearchField';
import TableFull from '../../../components/Table';
import ErrorField from '../../../components/ErrorField';
import ContactUsAPI, { ContactUsPayload } from '../../../api/contactus-api';
import PaymentAPI, { get_member_list, getmasterfare, gettrx_penarikan } from '../../../api/payment_api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';

// import Payment_form_buat_penagihan_ipl from '../../../components/modals/payment_form_manage_buatpembayaran';

type Props = {};

const Mrt_3_bendahara_manage_ipl: React.FC<Props> = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const [payloadgetmasterfare, set_getmasterfare] = useState<getmasterfare>({})
    const [data_Manage_Fare_filter, setManage_Fare_filter] = useState([])
    const [data_Manage_Fare, setManage_Fare] = useState([])

    const [payloadgettrx_penarikan, set_gettrx_penarikan] = useState<gettrx_penarikan>({})
    const [data_gettrx_penarikan_filter, setgettrx_penarikan_filter] = useState([])
    const [data_gettrx_penarikan, setgettrx_penarikan] = useState([])
    
    const [payload_getmember, set_getmember] = useState<get_member_list>({})
    const [data_getpayload_getlistmember_filter, setgetpayload_getlistmember_filter] = useState([])
    const [data_getpayload_getlistmember, setgetpayload_getlistmember] = useState([])

    // SHOW UI
    
    const [selectedpayloadgetmasterfare, setpayloadgetmasterfare] = useState<getmasterfare>({})
    const [modal_editconfigfare, showModal_editconfigfare] = useState(false)
    const [modal_formmasterlistmember, showModal_modal_formmasterlistmember] = useState(false)
    const [modal_editconfigpenarikan, showModal_editconfigpenarikan] = useState(false)

    const [loader, showLoader] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    

    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const [payloadUserPayload_byblok, set_UserPayload_byblok] = useState<UserPayload_byblok>({})
    
    const [data_codegenerated, set_data_codegenerated] = useState('')
    const [data_clucode, set_data_clucode] = useState("")
    const [data_blok_name, set_data_blok_name] = useState('')
    const [data_number_home, set_data_number_home] = useState('')

    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null;

    payloadUserPayload_byblok.code_generated = user.code_generated;
    payloadUserPayload_byblok.status_user = "1";

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => 
    {
        set_data_clucode(user.clu_code);
        set_data_codegenerated(user.code_generated);
        set_data_blok_name(user.blok_name);
        set_data_number_home(user.number_home);
        showLoader(true)

        // ======================================================================= CONFIG FARE
        
        payloadgetmasterfare.username = user.username;
        payloadgetmasterfare.code_generated = user.code_generated;

        payloadgetmasterfare.clu_code = user.clu_code;
        payloadgetmasterfare.blok_name = user.blok_name;
        payloadgetmasterfare.opr_subject_var = 'payment';
        payloadgetmasterfare.role_type = 1;
        const res = await PaymentAPI.opr_getmasterfare(payloadgetmasterfare);

        showLoader(false)
        // console.log('opr_getmasterfare :', res)
        if(res.data){
            if(res.data.code === 0){
                // console.log('opr_getmasterfare :', res.data.data);
                setManage_Fare(res.data.data);
                setManage_Fare_filter(res.data.data);
                
                // console.log('setManage_Fare :', data_Manage_Fare);
            // } else if(res.data.code === 99){
            //     navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
        // ======================================================================= LIST PENARIKAN

        payloadgettrx_penarikan.username = user.username;
        payloadgettrx_penarikan.code_generated = user.code_generated;

        payloadgettrx_penarikan.clu_code = user.clu_code;
        payloadgettrx_penarikan.blok_name = user.blok_name;
        payloadgettrx_penarikan.opr_subject_var = 'payment';
        payloadgettrx_penarikan.role_type = 1;
        const res_1 = await PaymentAPI.opr_gettrx_request(payloadgettrx_penarikan);

        showLoader(false)
        // console.log('opr_gettrx_request :', res_1)
        if(res_1.data){
            if(res_1.data.code === 0){
                // console.log('opr_getmasterfare :', res.data.data);
                setgettrx_penarikan(res_1.data.data);
                setgettrx_penarikan_filter(res_1.data.data);
                
                // console.log('setManage_Fare :', data_Manage_Fare);
            // } else if(res.data.code === 99){
            //     navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
        // ======================================================================= LIST PENARIKAN

        payload_getmember.username = user.username;
        payload_getmember.code_generated = user.code_generated;

        payload_getmember.clu_code = user.clu_code;
        payload_getmember.opr_subject_var = 'payment';
        payload_getmember.role_type = 1;
        const res_2 = await PaymentAPI.opr_getlist_mastermember(payload_getmember);

        showLoader(false)
        // console.log('opr_getmember_list :', res_2)
        if(res_2.data){
            if(res_2.data.code === 0){
                // console.log('opr_getmasterfare :', res.data.data);
                setgetpayload_getlistmember(res_2.data.data);
                setgetpayload_getlistmember_filter(res_2.data.data);
                
                // console.log('setManage_Fare :', data_Manage_Fare);
            // } else if(res.data.code === 99){
            //     navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchData = useCallback( async () => {
        showLoader(true)
        payloadgetmasterfare.clu_code = user.clu_code;
        payloadgetmasterfare.blok_name = user.blok_name;
        payloadgetmasterfare.opr_subject_var = 'payment';
        payloadgetmasterfare.role_type = 1;
        const res = await PaymentAPI.opr_getmasterfare(payloadgetmasterfare);

        showLoader(false)
        console.log('opr_getmasterfare :', res)
        if(res.data){
            if(res.data.code === 0){
                setdefaultValue(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }, [navigate])

    const onSubmitForm: SubmitHandler<ContactUsPayload> = async ({address, telp, email, whatsapp_number}) => {
        showLoader(true)
        const res = await ContactUsAPI.update({address, telp, email, whatsapp_number } as ContactUsPayload)
        
        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data){
            if(res.data.code === 0){
                resetForm()
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }

    const handle_ui_editconfigfare =  (selectedData: getmasterfare) => {
        setIsUpdate(true)
        setpayloadgetmasterfare(selectedData)
        showModal_editconfigfare(true)
    }
    
    const handle_ui_editpenarikan =  (selectedData: UserPayload) => {
        // setSelectedUser(selectedData)
        // setIsUpdate(true)
        // showModal_editconfigpenarikan(true)
        toast.error("Belum Ready")
    }
    const handle_ui_masterlistmember_detail =  (selectedData: UserPayload) => {
        // setSelectedUser(selectedData)
        // setIsUpdate(true)
        showModal_modal_formmasterlistmember(true)
        // toast.error("Belum Ready")
    }

    const handle_ui_masterlistmember_add=  (selectedData: UserPayload) => {
        // setSelectedUser(selectedData)
        // setIsUpdate(true)
        showModal_modal_formmasterlistmember(true)
        // toast.error("Belum Ready")
    }
    
    const handleReceiveDataForm_editconfig = async (data: getmasterfare) => {
        showLoader(true)
        // console.log('handleReceiveDataForm_editconfig : ', data)
        const res = await PaymentAPI.opr_editmasterfare(data);
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleReceiveDataForm_master_listmember = async (data: getmasterfare) => {
        showLoader(true)
        // console.log('handleReceiveDataForm_editconfig : ', data)
        const res = await PaymentAPI.opr_editmasterfare(data);
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (data: UserPayload) => {
        const res = await deleteUser(data.id || 1)

        // console.log("DELETE USER :", res)
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
            }else{
                toast.error(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const create_payment = () => {
        toast.error("Fitur Belum siap : Buat Pembayaran")
    }
    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModal_editconfigfare(false)
        showModal_editconfigpenarikan(false)
        showModal_modal_formmasterlistmember(false)
        showLoader(false)
    }

    const columns_master_config_fare = [
        {
            Header: () => <span className='p-4'>Clu Code</span>,
            Footer: 'clu_code',
            accessor: 'clu_code',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Blok Name</span>,
            Footer: 'blok_name',
            accessor: 'blok_name',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Type Config</span>,
            Footer: 'opr_subject_var',
            accessor: 'opr_subject_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Kode Config</span>,
            Footer: 'opr_parameter_var',
            accessor: 'opr_parameter_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Desckripsi</span>,
            Footer: 'opr_desc_var',
            accessor: 'opr_desc_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nilai</span>,
            Footer: 'opr_value_var',
            accessor: 'opr_value_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_editconfigfare(data)}></i>
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        }
    ]
    const columns_trx_penarikan = [
        {
            Header: () => <span className='p-4'>Kode Pembayaran</span>,
            Footer: 'type_payment_code_var',
            accessor: 'type_payment_code_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Deskripsi Pembayaran</span>,
            Footer: 'type_payment_desc_var',
            accessor: 'type_payment_desc_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Start Time</span>,
            Footer: 'time_billing_start',
            accessor: 'time_billing_start',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Total Nilai</span>,
            Footer: 'nominal_int',
            accessor: 'nominal_int',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_editpenarikan(data)}></i>
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )

                return <></>
            }
        }
    ]
    const columns_trx_listmastermember = [
        {
            Header: () => <span className='p-4'>Kode List Member</span>,
            Footer: 'list_member_code_var',
            accessor: 'list_member_code_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Deskripsi List Member</span>,
            Footer: 'list_member_desc_var',
            accessor: 'list_member_desc_var',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Nama Blok</span>,
            Footer: 'blok_name',
            accessor: 'blok_name',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Quantity</span>,
            Footer: 'quantity_member',
            accessor: 'quantity_member',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer"    onClick={() =>handle_ui_masterlistmember_detail(data)}>List</i>
                        <i className="ri-file-list-2-fill text-primary hover:text-green-400 cursor-pointer" onClick={() =>handle_ui_masterlistmember_add(data)}>Add</i>
                    </div>
                )

                return <></>
            }
        }
    ]
    
    
    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...data_Manage_Fare_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.opr_desc_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.opr_subject_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.opr_parameter_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setManage_Fare_filter(filtered)
        }else{
            setManage_Fare_filter(data_Manage_Fare)
        }
    }
    const handleSearch_penarikan = (event: any) => {
        event.preventDefault()

        const newData = [...data_Manage_Fare_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.time_billing_start?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.type_payment_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.type_payment_desc_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setManage_Fare_filter(filtered)
        }else{
            setManage_Fare_filter(data_Manage_Fare)
        }
    }
    const handleSearch_member= (event: any) => {
        event.preventDefault()

        const newData = [...data_Manage_Fare_filter]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.time_billing_start?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.type_payment_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.type_payment_desc_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setManage_Fare_filter(filtered)
        }else{
            setManage_Fare_filter(data_Manage_Fare)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
                <h1 className='text-base font-semibold'>MANAGEMENT IPL</h1>
                <div className="border-2 border-dark rounded-lg mt-5">
                    <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                        <h1 className='font-bold'>TABEL TARIF IPL</h1>
                    </div>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Tambah Tarif IURAN</button>
                            </div>
                        </div>
                    </div>
                    <TableFull dataTable={data_Manage_Fare_filter} columnTable={columns_master_config_fare} />
                    </div>
                </div>
            </div>
            <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
                <div className="border-2 border-dark rounded-lg ">
                    <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                        <h1 className='font-bold'>TABEL PENARIKAN IPL</h1>
                    </div>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch_penarikan} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Tambah Penarikan Pembayaran</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={data_gettrx_penarikan_filter} columnTable={columns_trx_penarikan} />
                    </div>
                </div>
            </div>
            <div className='flex item-center flex-col p-5 md:p-6 mb-auto'>
                <div className="border-2 border-dark rounded-lg ">
                    <div className="bg-dark p-5 text-white rounded-tl-md rounded-tr-md">
                        <h1 className='font-bold'>TABEL LIST MASTER MEMBER</h1>
                    </div>
                    <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch_member} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Tambah Kategori member</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={data_getpayload_getlistmember_filter} columnTable={columns_trx_listmastermember} />
                    </div>
                </div>
            </div>


            {/* {modal_editconfigfare && <Payment_form_buat_penagihan_ipl data={selectedpayloadgetmasterfare} onClose={resetForm} onSubmit={handleReceiveDataForm_editconfig} />} */}
            
            <div className='flex item-center flex-col p-6'>
                {/* <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>PENARIKAN IPL</h1>
                    </div>

                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Buat Penarikan</button>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Ubah Tarif</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={filterData} columnTable={columns_payment} />
                </div> */}
                {/* <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>MEMBER PENARIKAN</h1>
                    </div>

                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search data...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Buat Penarikan</button>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={create_payment}>Ubah Tarif</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={filterData} columnTable={columns_payment_member} />
                </div> */}
            </div>
            {/* {modalUser && <UserForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />} */}
        </>
    );
}

export default Mrt_3_bendahara_manage_ipl;