import React from 'react';
import * as XLSX from 'xlsx';

interface XLSImporterProps {
    isShow: boolean;
    onImport: (isTodo: number, data: any[]) => void;
}

const XLSImporter: React.FC<XLSImporterProps> = ({isShow, onImport }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        isShow = true;
        const file = event.target.files?.[0];
        if (file) {
            console.log('detec file');
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => 
            {
                console.log('onload');
                try {
                    const data = e.target?.result;
                    if (data) {
                        const workbook = XLSX.read(data, { type: 'binary' });
                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];
                        // const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        
                        // Parse sheet data to JSON
                        const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                        onImport(0, jsonData);
                        console.log('success');
                    } else {
                        throw new Error('No data found in the file.');
                    }
                } catch (error) {
                    console.error('Error reading file:', error);
                    onImport(1, []); // Notify with an error status
                }
            };
            reader.readAsBinaryString(file);
        }
        else{
            onImport(2, []);
            console.log('file 2');
        }
    };

    return (
        <div>
            <label className="file-upload-button">
                <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} style={{ display: 'none' }} />
                Upload File
            </label>
            {/* <button>Import XLS</button> */}
        </div>
    );
};

export default XLSImporter;