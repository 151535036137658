import axios from "axios"
import { baseURL } from "./common-api"


export interface trx_updatepayment {
    clu_code?: string,
    blok_name?: string,
    code_generated?: string,
    type_payment_code_var?: string,
    nominal_payment?: string,
    desc_payment?: string,
    image?: any,
    mode_payment?: string,
    status_int?: string,
}

export interface UserPayload {
    id?: number
    username?: string,
    password?: string,
    fullname?: string,
    email?: string,
    role_id?: number
}

export interface UserPayload_change_password {
    username?: string,
    password?: string,
    confirm_password?: string
}

export interface UserPayload_change_data_before {
    code_generate?: string,
    clu_name?: string,
    blok_name?: string,
    number_home?: string,
    username?: string,
    fullname?: string
}
export interface UserPayload_change_data{
    clu_code?: string,
    code_generated?: string,
    blok_name?: string,
    number_home?: string,
    fullname?: string,
    username?: string,
    email?: string,
    phone_number?: string,
    identity_gender?: string,
    identity_agama?: string,
    identity_tempatlahir?: string,
    identity_tanggallahir?: string,
    identity_statuskeluarga?: string,
    identity_statuspernikahan?: string,
    identity_kewarganegaraan?: string,
    identity_pekerjaan?: string,
    status_keluarga?: string,
    // password?: string,
    // role_id?: string,
    // identity_nik?: string,
    // identity_nokk?: string,
    image_photo?: string
}

export interface UserPayload_byaddress {
    code_generated?: string
}

export interface numberblokPayload {
    clu_name?: string,
    blok_name?: string,
    status_int?: number
}

export interface UserPayload_byblok {
    username?: string,
    code_generated?: string,
    status_user?: string
}

export const gethome_blok = async (payload: numberblokPayload) => {
    try {
        const data = await axios.post(baseURL + '/api/home-getblok', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const gethome_numberbyblok = async (payload: numberblokPayload) => {
    try {
        const data = await axios.post(baseURL + '/api/home-getnumberbyblok', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneUser = async (userID: number) => {
    try {
        const data = await axios.get(`${baseURL}/api/user-one?id=${userID}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getAllUser = async () => {
    try {
        const data = await axios.get(baseURL + '/api/user-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const getAllUser_beforeupdate = async (payloaduser: UserPayload_change_data_before)  => {
    try {
        // console.log("> UserPayload_byaddress : "+payloaduser.code_generated)
        const data = await axios.post(baseURL + '/api/user-byaddress_detail', payloaduser, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        // console.log("> getAllUser_byaddress : "+data)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const getAllUser_byaddress = async (payloaduser: UserPayload_byaddress)  => {
    try {
        // console.log("> UserPayload_byaddress : "+payloaduser.code_generated)
        const data = await axios.post(baseURL + '/api/user-byaddress', payloaduser, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        // console.log("> getAllUser_byaddress : "+data)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
export const getAllUser_byblok = async (payloaduser: UserPayload_byblok)  => {
    try {
        // console.log("> UserPayload_byaddress : ", payloaduser)
        const data = await axios.post(baseURL + '/api/user-byblok', payloaduser, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        // console.log("> getAllUser_byaddress : "+data)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewUser = async (payload: UserPayload) => {
    try {
        const data = await axios.post(baseURL + '/api/user-new', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateUser = async (userID: number, payload: UserPayload_change_data) => {
    try {
        const data = await axios.patch(baseURL + '/api/user-update/' + userID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateUser_data = async (userID: number, payload: UserPayload_change_data) => {
    try {
        const data = await axios.post(baseURL + '/api/user-update_data/', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        console.log(data)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateUser_password = async (payload: UserPayload_change_password) => {
    try {
        const data = await axios.post(baseURL + '/api/user-update_password/', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteUser = async (userID: number) => {
    try {
        const data = await axios.delete(baseURL + '/api/user-delete/' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}