import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUser_password,updateUser_data, createNewUser, deleteUser, getAllUser,getAllUser_byaddress, updateUser, UserPayload,UserPayload_change_data, UserPayload_byaddress, UserPayload_change_password } from '../../api/user-api';
import UserForm from '../../components/modals/UserForm';
import UserForm_changepass from '../../components/modals/UserForm_changepass';

import UserImport from '../../components/modals/UserImport';
import SearchField from '../../components/SearchField';
import TableFull from '../../components/Table';
import ErrorField from '../../components/ErrorField';
import ContactUsAPI, { ContactUsPayload } from '../../api/contactus-api';
import * as XLSX from 'xlsx';
import XLSImporters from '../../components/XLSImporter';

type Props = {};

const User_myprofile: React.FC<Props> = () => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const [jsonDataArray_set, setJsonDataArray] = useState< { [key: string]: any }[]>([]);
    const [modalUserImport, showModalUserImport] = useState(false)

    const [modalUser_changepass, showmodalUser_changepass] = useState(false)
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const [payloadUserPayload_byaddress, setUserPayload_byaddress] = useState<UserPayload_byaddress>({})
    
    const [data_codegenerated, set_data_codegenerated] = useState('')
    const [data_clucode, set_data_clucode] = useState("")
    const [data_blok_name, set_data_blok_name] = useState('')
    const [data_number_home, set_data_number_home] = useState('')

    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null;


    payloadUserPayload_byaddress.code_generated = user.code_generated;

    const navigate = useNavigate()

    const fetchUser = useCallback( async () => {
        
        set_data_clucode(user.clu_code);
        set_data_codegenerated(user.code_generated);
        set_data_blok_name(user.blok_name);
        set_data_number_home(user.number_home);
        showLoader(true)

        // console.log("USER LOCAL : "+payloadUserPayload_byaddress.code_generated)
        const res = await getAllUser_byaddress(payloadUserPayload_byaddress as UserPayload_byaddress)

        showLoader(false)
        // console.log('Fetch User :', res)
        if(res.data){
            if(res.data.code === 0){
                setDataUser(res.data.data.rows)
                setFilterData(res.data.data.rows)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchUser()
        }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await ContactUsAPI.get()

        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data){
            if(res.data.code === 0){
                setdefaultValue(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }, [navigate, setdefaultValue])

    const onSubmitForm: SubmitHandler<ContactUsPayload> = async ({address, telp, email, whatsapp_number}) => {
        showLoader(true)
        const res = await ContactUsAPI.update({address, telp, email, whatsapp_number } as ContactUsPayload)
        
        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data){
            if(res.data.code === 0){
                resetForm()
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }
    const handleReceiveDataForm_changepass = async (data: UserPayload_change_password) => {
        showLoader(true)
        
        //alert(data.username + " | " + data.password + " | " + data.confirm_password)
        //console.log(data)
        let res = null
        res = await updateUser_password(data)

        // console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }
    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleUserImportForm = async (todo:boolean ,data: UserPayload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            res = await createNewUser(data)
        }else{
            // res = await updateUser(selectedUser.id || 1, data)
        }

        // console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }
    
    const handleimportuser = async (data: any) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            res = await createNewUser(data)
        }else{
            res = await updateUser(selectedUser.id || 1, data)
        }

        // console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }
    // const handleReceiveDataForm = async (data: UserPayload) => {
    //     showLoader(true)
        
    //     let res = null
    //     if(!isUpdate){
    //         res = await createNewUser(data)
    //     }else{
    //         // res = await updateUser(selectedUser.id || 1, data)
    //     }

    //     // console.log('Create/Update User :', res)
        
    //     if(res.data){
    //         if(res.data.code === 0){
    //             toast.success('Success')
    //             fetchUser()
    //             resetForm()
    //         }else{
    //             toast.error(res.data.message)
    //             showLoader(false)
    //         }
    //     }else{
    //         alert(`${res.config?.url} ${res.message}`)
    //     }
    // }
    const handleReceiveDataForm = async (data: UserPayload_change_data) => {
        showLoader(true)
        
        console.log('handleReceiveDataForm : ', data)
        let res = null
        res = await updateUser_data(selectedUser.id || 1, data)

        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }
    const handleDeleteItem = async (data: UserPayload) => {
        const res = await deleteUser(data.id || 1)

        // console.log("DELETE USER :", res)
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
            }else{
                toast.error(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
        showModalUserImport(false)
        showmodalUser_changepass(false)
    }
    const myprofile_adduser = () => {
        toast.error("Fitur Belum siap : Add User")
    }

    const handleImport = (todo: number, data: any[]) => {
        if(modalUserImport == false)
        {
            showModalUserImport(true)
        }
        if(data != null)
        {
            const jsonDataArray: { [key: string]: any }[] = [];
            data.forEach((row: any[], index:number) => 
            {
                if (row.length > 1) 
                {
                    if(index > 0)
                    {
                        const jsonDataObject: { [key: string]: any } = {};
                        jsonDataObject['no']                = index;
                        jsonDataObject['timestamp']         = row[0];
                        jsonDataObject['blok_rumah']        = row[1];
                        jsonDataObject['nomor_rumah']       = row[2];
                        jsonDataObject['nomor_kk']          = row[3];
                        jsonDataObject['nomor_nik']         = row[4];
                        jsonDataObject['nama_lengkap']      = row[5];
                        jsonDataObject['jenis_kelamin']     = row[6];
                        jsonDataObject['tempat_lahir']      = row[7];
                        jsonDataObject['tangggal_lahir']    = row[8];
                        jsonDataObject['agama']             = row[9];
                        jsonDataObject['pekerjaan']         = row[10];
                        jsonDataObject['kewarganegaraan']   = row[11];
                        jsonDataObject['status_tempat_tinggal']   = row[12];
                        jsonDataObject['email']             = row[13];
                        jsonDataObject['nomor_hp']          = row[14];
                        jsonDataObject['alamat_ktp']        = row[15];
                        jsonDataObject['upload_kk']         = row[16];
                        jsonDataObject['email_input']       = row[17];
                        jsonDataObject['status_hubungan_keluarga'] = row[18];
                        jsonDataObject['status_pernikahan'] = row[19];
                        jsonDataObject['pendidikan_terkahir']   = row[20];
                        jsonDataObject['username']          = row[21];
                        jsonDataObject['password']          = row[22];
                        jsonDataObject['rt']                = row[23];
                        jsonDataObject['rw']                = row[24];
                        
                        jsonDataArray.push(jsonDataObject);
                    }
                }
            });
            setJsonDataArray(jsonDataArray);
        }
        else
        {
            if(todo == 1)
            {
                console.log('Failed get data', data);
            }
            else
            {
                console.log('Cancel');
            }
        }
        // Process the imported data as needed
    };

    // const columns = [
    //     {
    //         Header: () => <span className='p-4'>No</span>,
    //         Footer: 'no',
    //         accessor: 'no',
    //         Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
    //     },
    //     {
    //         Header: () => <span className='p-4'>Name</span>,
    //         Footer: 'Fullname',
    //         accessor: 'fullname',
    //         Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
    //     },
    //     {
    //         Header: 'Username',
    //         Footer: 'Username',
    //         accessor: 'username',
    //     },
    //     {
    //         Header: 'Status Keluarga',
    //         Footer: 'status_keluarga',
    //         accessor: 'status_keluarga'
    //     },
    //     {
    //         Header: 'Tempat Lahir',
    //         Footer: 'Tempat Lahir',
    //         accessor: 'identity_tempatlahir'
    //     },
    //     {
    //         Header: 'Tanggal Lahir',
    //         Footer: 'Tanggal Lahir',
    //         accessor: 'identity_tanggallahir'
    //     },
    //     {
    //         Header: 'Action',
    //         Footer: 'Action',
    //         Cell: ({row}: any) => {
    //             const data = row.original
    //             return (
    //                 <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
    //                     <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i>
    //                     <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i>
    //                 </div>
    //             )

    //             return <></>
    //         }
    //     }
    // ]
    const handlepassword =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        // setIsUpdate(true)
        showmodalUser_changepass(true)
    }
    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no',
            accessor: 'no',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <div className="flex items-center cursor-pointer" onClick={() => handleEditData(data)}>
                            <i className="ri-edit-box-line text-primary hover:text-green-400"></i>
                            <i className="ml-2 text-sm  text-primary hover:text-green-400">Edit Data</i>
                        </div> 
                        <div className="flex items-center cursor-pointer" onClick={() => handlepassword(data)}>
                            <i className="ri-lock-password-fill text-black hover:text-green-400"></i>
                            <i className="ml-2 text-sm  text-black hover:text-green-400">Edit Password</i>
                        </div> 
                    </div>
                )
                return <></>
            }
        },
        {
            Header: () => <span className='p-4'>Akun</span>,
            Footer: 'status_user',
            accessor: 'status_user',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Name</span>,
            Footer: 'Fullname',
            accessor: 'fullname',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Username</span>,
            Footer: 'Username',
            accessor: 'username',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Hub. Keluarga</span>,
            Footer: 'status_keluarga',
            accessor: 'status_keluarga',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        }
    ]
    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.role_name?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col px-6 md:px-10 pt-10'>
                <div className='w-full bg-gray-700 rounded-lg shadow-xl pt-3 pb-5 md:p-6'>
                    <div className='flex items-center justify-between text-white px-8 pt-3 pb-8'>
                        <h2 className='font-semibold'>Detail Rumah</h2>
                        <i className="ri-edit-box-line text-2xl hover:text-primary focus:ring-2 focus:ring-offset-2 focus:ring-primary cursor-pointer" onClick={()=>setIsUpdate(true)}></i>
                    </div>
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <fieldset disabled={!isUpdate} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">

                        <div className="md:flex md:space-x-10  text-white  space-y-5 md:space-y-0">
                            {/* <div className="flex-1">
                                <h1 >Clu Code</h1>
                                <input id="clu_code" type="text" value={data_clucode} disabled={true} className="text-input"/>
                                <ErrorField errors={errors} name='clu_code' />
                            </div> */}
                            <div className="flex-1">
                                <h1 >Code Generate</h1>
                                <input id="code_generated" type="text" value={data_codegenerated} disabled={true} className="text-input"/>
                                <ErrorField errors={errors} name='code_generated' />
                            </div>
                        </div>

                        <div className="md:flex md:space-x-10  text-white space-y-5 md:space-y-0">
                            {/* <div className="flex-1">
                                <h1 >Nama Blok</h1>
                                <input id="nama_blok" type="text" value={data_blok_name} disabled={true} className="text-input"/>
                                <ErrorField errors={errors} name='nama_blok' />
                            </div>
                            <div className="flex-1">
                                <h1 >Nomor Rumah</h1>
                                <input id="nomor_rumah" type="text" value={data_number_home} disabled={true} className="text-input"/>
                                <ErrorField errors={errors} name='nomor_rumah' />
                            </div> */}
                            {/* <div className="w-full">
                                <label className="label-form">Blok Rumah</label>
                                <select id="blok_rumah" className="input-form" {...register('blok_rumah', {required: 'blok_rumah is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_ROLE.map((item, key) =>
                                        <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='blok_rumah' />
                            </div>
                            <div className="w-full">
                                <label className="label-form">Nomor Rumah</label>
                                <select id="number_home" className="input-form" {...register('number_home', {required: 'number_home is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_ROLE.map((item, key) =>
                                        <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='number_home' />
                            </div> */}
                        </div>
                        {/* <div className="md:flex md:space-x-10 space-y-5 md:space-y-0 text-center mt-10">
                            <div className="w-full">
                                <label htmlFor="images" className="label-form">Images</label>
                                <div className='md:space-x-10 space-y-5'>
                                    {dataAbout.images.map((image: any, key: number) =>
                                        <LazyLoadImage key={key} src={image} alt='' effect='blur' className='mx-1 md:m-0 h-44 border border-gray-100 rounded-md'  />
                                    )}
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Modal footer --> */}
                        {isUpdate &&
                        <div className="flex items-center pt-8 space-x-4">
                            {/* <button type='submit' className="btn-primary px-7">Save</button> */}
                            <button type='reset' className="btn-secondary" onClick={resetForm}>Close</button>
                        </div>
                        }

                    </fieldset>
                    </form>
                </div>
            </div>

            <div className='flex item-center flex-col p-6'>
                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>Data Keluarga</h1>
                    </div>
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search user...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                {/* <XLSImporters isShow={modalUserImport} onImport={handleImport} /> */}
                                <button type='reset' className="btn-secondary" onClick={myprofile_adduser}>Tambah User</button>
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={filterData} columnTable={columns} />

                </div>
            </div>
            {/* {modalUser && <UserForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />} */}
            {modalUser && <UserForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalUser_changepass && <UserForm_changepass data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm_changepass} />}
            
            {/* {modalUserImport && <UserImport jsonDataArray_show={jsonDataArray_set} data={selectedUser} onClose={resetForm} onSubmit={handleimportuser} />} */}
        </>
    );
}

export default User_myprofile;