import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {getAllUser_beforeupdate, UserPayload_change_data, trx_updatepayment } from '../../api/user-api';
import ErrorField from '../ErrorField';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment, { Moment } from 'moment';

import FullScreenImage from '../../components/FullscreenImage';
import PaymentAPI, { get_member_list, get_member_master_list , trx_updatepayment_api, trx_updatepayment_bendahara_api, getmasterfare} from '../../api/payment_api';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LIST_STATUS_PERNIKAHAN = [
	{ id: 1, name: 'BELUM KAWIN' },
	{ id: 2, name: 'KAWIN' },
	{ id: 3, name: 'CERAI HIDUP' },
	{ id: 4, name: 'CERAI MENINGGAL' }
]
const LIST_RT = [
	{ id: 1, name: '0' }
]
const LIST_RW = [
	{ id: 1, name: '0' }
]
const LIST_AGAMA = [
	{ id: 1, name: 'ISLAM' },
	{ id: 2, name: 'KRISTEN (PROTESTAN)' },
	{ id: 3, name: 'KATOLIK' },
	{ id: 4, name: 'HINDU' },
	{ id: 5, name: 'BUDHA' },
	{ id: 6, name: 'KONGHUCU' }
]

const LIST_STATUS_KELUARGA = [
    { id: 1, name: 'KEPALA KELUARGA' },
    { id: 2, name: 'ISTRI' },
    { id: 3, name: 'ANAK' },
    { id: 4, name: 'ORANG TUA' },
    { id: 5, name: 'SAURDARA' },
    { id: 6, name: 'Lain-lain' }
]

const LIST_ROLE = [
	{ id: 1, name: 'Warga' }
]

const LIST_JENISKELAMIN = [
	{ id: 1, name: 'laki-laki' },
	{ id: 2, name: 'perempuan' }
]

interface payment_form_pembayaran_manualExtend extends trx_updatepayment_api {
    confirm_password: string
}

type Props = {
    isEditable?: any,
    data?: any,
    onSubmit: (data: trx_updatepayment_api) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
    onAccept: (data: trx_updatepayment_bendahara_api) => void
    onReject: (data: trx_updatepayment_bendahara_api) => void
};

const Payment_form_pembayaran_manual: React.FC<Props> = ({ isEditable, data, onSubmit, onClose, onAccept, onReject }) => {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });
        
        const [combobox_masterlist, set_masterlist] = useState<get_member_master_list[]>([])
        // const [combobox_masterlist, set_masterlist] = useState<get_member_master_list>()

        const [payload_getmember, set_getmember] = useState<get_member_list>({})

        const [selectedDate, setSelectedDate] = useState(new Date());
        const [selectedDate_str, setSelectedDate_str] = useState('');
        const [data_image, setdataimage] = useState('');
        
        const [data_role, set_data_role] = useState('');

        const [fare_PW_IPL, set_fare_PW_IPL] = useState('');
        const [fare_kas, set_fare_kas] = useState('');
        const [fare_olahraga, set_fare_olahraga] = useState('');
        const [fare_danasosial, set_fare_danasosial] = useState('');

        const [data_istatus_before, setstatus_before] = useState(0);

        const [payloadgetmasterfare, set_getmasterfare] = useState<getmasterfare>({})
        const [data_Manage_Fare_filter, setManage_Fare_filter] = useState([])
        const [data_Manage_Fare, setManage_Fare] = useState([])
        
        const userLocal = localStorage.getItem('dataUser')
        const user = userLocal? JSON.parse(userLocal || '') : null;


       
    const handleImageload = (event: any) => {
        event.preventDefault()
        setdataimage(event.target.files[0])
        // console.log('IMAGE CHANGE : ', event.target.files[0])
    }
    const handleDateChanges = (value: string | Moment) => {
        // console.log('> 1 ',value);
        if (typeof value === 'string') 
        {
            // console.log('>> 1 ',selectedDate);
            setSelectedDate(new Date(value));
        } 
        else
        {
            const momentDate = moment(value);
            setSelectedDate(momentDate.toDate());
            // console.log('>> 2 ',selectedDate);
        }
            //else {
        //     setSelectedDate(value.toDate());
        //     setSelectedDate_str(value.format('YYYY-MM-DD'));
        // }
        // console.log('>>> ',selectedDate);
        setValue('identity_tanggallahir',selectedDate);
    };

    const setdefaultValue = useCallback ((obj: any) => {
        // console.log(obj)
        Object.keys(obj).map(key => {
            // if(key === 'image_var'){
            //     console.log(key,' | ', data.identity_agama , ' - ' , LIST_AGAMA );
            //     return setValue(key, LIST_AGAMA.find(item => item.name.toLowerCase() === data.identity_agama.toLowerCase())?.name, { shouldValidate: true })
            // }
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchUser = useCallback( async () => {
        payload_getmember.username = user.username;
        payload_getmember.code_generated = user.code_generated;
        payload_getmember.clu_code = data.clu_code;
        payload_getmember.opr_subject_var = 'payment';
        payload_getmember.role_type = 1;
        // const res_2 = await PaymentAPI.opr_getlist_mastermember(payload_getmember);

        // if(res_2.data){
        //     if(res_2.data.code === 0){
        //         console.log('opr_getlist_mastermember :', res_2.data.data);
        //         set_masterlist(res_2.data.data);
        //         console.log('combobox_masterlist :', combobox_masterlist);
        //     }else{
        //         toast.error(res_2.data.message)
        //     }
        // }
    }, [])

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentImage, setCurrentImage] = useState<string | null>(null);

    const handleaccept = (data: trx_updatepayment_bendahara_api) => {
        data.nominal_kebersihan = nominal_kebersihan
        data.nominal_kas = nominal_kas
        data.nominal_danasosial = nominal_danasosial
        data.nominal_olahraga = nominal_olahraga
        console.log(data)
        // const payload = 
        // {
        //     description_by_create, 
        //     clu_code, blok_name, code_generated, type_payment_code_var, nominal_payment, notes_payment, images, mode_payment, status_int,status_after_int, edit_by_var, dir, nominal_kebersihan, nominal_kas, nominal_danasosial, nominal_olahraga
        // } as trx_updatepayment_bendahara_api

        onAccept(data)
    };

    const handleImageClick = (src: string) => {
        setCurrentImage(src);
        setIsFullScreen(true);
    };

    const handleCloseFullScreen = () => {
        setIsFullScreen(false);
        setCurrentImage(null);
    };

    useEffect(() => {
        fetchUser()
        if(Object.keys(data).length !== 0){
            console.log('DATA : ',data)
            setnominal_kebersihan(data.nominal_kebersihan);
            setnominal_kas(data.nominal_kas);
            setnominal_danasosial(data.nominal_danasosial);
            setnominal_olahraga(data.nominal_olahraga);
            // set_data_role(data.role_type)
            let nominal_payment = Number(data.nominal_payment);
            let nominal_int = Number(data.nominal_int);
            let nominal_kas = Number(data.nominal_kas);
            let nominal_kebersihan = Number(data.nominal_kebersihan);
            let nominal_danasosial = Number(data.nominal_danasosial);
            let nominal_olahraga = 0;
            let counting = nominal_payment - nominal_int
            if(counting >= 0)
            {
                counting = nominal_payment - (nominal_kas + nominal_kebersihan+ nominal_danasosial+ nominal_olahraga)
                if(counting == 0)
                {
                    data.nominal_notes = "PAS"
                }
                else if(counting > 0)
                {
                    data.nominal_notes = "LEBIH BAYAR : " + counting
                }
                else
                {
                    data.nominal_notes = "KURANG BAYAR : " + counting
                }
            }
            else
            {
                data.nominal_notes = "KURANG BAYAR : " + counting
            }

            setdataimage(data.image_var)
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])

    const [nominal_kebersihan, setnominal_kebersihan] = useState('');
    const [nominal_kas, setnominal_kas] = useState('');
    const [nominal_danasosial, setnominal_danasosial] = useState('');
    const [nominal_olahraga, setnominal_olahraga] = useState('');
    
    const handleInputnominalkebersihan = (event: React.ChangeEvent<HTMLInputElement>) => {
        setnominal_kebersihan(event.target.value);
    };
    const handleInputnominalkas = (event: React.ChangeEvent<HTMLInputElement>) => {
        setnominal_kas(event.target.value);
    };
    const handleInputnominaldanasosial = (event: React.ChangeEvent<HTMLInputElement>) => {
        setnominal_danasosial(event.target.value);
    };
    const handleInputnominalolahraga = (event: React.ChangeEvent<HTMLInputElement>) => {
        setnominal_olahraga(event.target.value);
    };
        
    const onSubmitForm: SubmitHandler<payment_form_pembayaran_manualExtend> = ({description_by_create, clu_code, blok_name, code_generated, type_payment_code_var, nominal_payment, notes_payment, images, mode_payment, status_int,status_after_int, edit_by_var , dir, nominal_kebersihan, nominal_kas, nominal_danasosial, nominal_olahraga}) => {
        mode_payment = '1';
        edit_by_var = user.username;
        images = data_image;
        status_after_int = '1';
        dir = 'images/payment';
        description_by_create = data.description_by_create;
        // if(images?.value){
        //     for (let i = 0; i < images.files.length; i++) {
        //         images[i] = images.files[i]
        //     }
        // }
        const payload = {description_by_create, 
            clu_code, blok_name, code_generated, type_payment_code_var, nominal_payment, notes_payment, images, mode_payment, status_int,status_after_int, edit_by_var, dir, nominal_kebersihan, nominal_kas, nominal_danasosial, nominal_olahraga
        } as trx_updatepayment_api

        onSubmit(payload)
    }
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
    
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                        {data.status_int == '0' ? (
                            <h3 className="text-xl font-medium text-white pb-4">FORM LAPORAN PEMBAYARAN</h3>
                        ) : data.status_int == '1' ? (
                            <h3 className="text-xl font-medium text-white pb-4">FORM LAPORAN PEMBAYARAN</h3>
                        ) : (
                            <h3 className="text-xl font-medium text-white pb-4">FORM SELESAI</h3>
                        )}
    
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label className="label-form">Code Clu</label>
                                <input type="text" id="clu_code" className="input-form" disabled={true}
                                    {...register('clu_code', {
                                        required: 'clu_code is required',
                                        maxLength: {value: 50, message: 'clu_code is too long'}
                                    })}
                                />
                                <ErrorField errors={errors} name='clu_code' />
                            </div>
                            <div className="w-full">
                                <label className="label-form">Code Payment</label>
                                <input type="text" id="type_payment_code_var" className="input-form"   disabled={true}
                                    {...register('type_payment_code_var', {
                                        required: 'type_payment_code_var is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='type_payment_code_var' />
                            </div>
                        </div>
                        
                        
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="nominal_int" className="label-form">Nominal Pembayaran</label>
                                <input type="text" id="nominal_int" className="input-form" placeholder="" disabled={true}
                                    {...register('nominal_int', {
                                        required: 'nominal_int is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='nominal_int' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="description_by_create" className="label-form">Deskripsi Pembayaran</label>
                                <input type="text" id="description_by_create" className="input-form" placeholder="" disabled={true}
                                    {...register('description_by_create', {
                                        required: 'description_by_create is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='description_by_create' />
                            </div>
                            
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="nominal_payment" className="label-form">Jumlah yang dibayar</label>
                                <input type="text" id="nominal_payment" className="input-form" placeholder="" 
                                    {...register('nominal_payment', {
                                        required: 'nominal_payment is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='nominal_payment' />
                            </div>
                           
                            <div className="w-full">
                                <label htmlFor="notes_payment" className="label-form">Note Pembayaran</label>
                                <input type="text" id="notes_payment" className="input-form" placeholder="" 
                                    {...register('notes_payment', {
                                        required: 'notes_payment is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='desc_payment' />
                            </div>
                           
                            
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0 text-center mt-10">
                            {data.status_int == '0' ? (
                                <div className="w-full">
                                    <label htmlFor="images" className="label-form">Upload Bukti Bayar</label>
                                    <input type="file" id="images" 
                                        className="input-form" 
                                        accept='image/jpeg,image/jpg,image/png' 
                                        onChange={handleImageload}
                                        />
                                   
                                    <ErrorField errors={errors} name='images' />
                                </div>
                            ) : 
                                <div className="w-full">
                                    <label htmlFor="images" className="label-form">Bukti Bayar</label>
                                    <LazyLoadImage
                                        src={data_image}
                                        alt=''
                                        effect='blur'
                                        onClick={() => handleImageClick(data_image)}
                                        className='mx-1 md:m-0 h-44 border border-gray-100 rounded-md'
                                    />
                                </div>
                            }
                        </div>
                        {data.status_int == '1' ? ( 
                            <div>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="nominal_notes" className="label-form">Nominal Notes</label>
                                    <input type="text" id="nominal_notes" className="input-form" placeholder="" disabled={true}
                                        {...register('nominal_notes', {
                                            required: 'nominal_notes is required',
                                        })}
                                    />
                                    <ErrorField errors={errors} name='nominal_notes' />
                                </div>
                            </div>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="nominal_kebersihan" className="label-form">Nominal Kebersihan</label>
                                    <input type="text" id="nominal_kebersihan" 
                                        className="input-form" 
                                        placeholder="" 
                                        value={nominal_kebersihan}
                                        onChange={handleInputnominalkebersihan}
                                    />
                                    <ErrorField errors={errors} name='nominal_kebersihan' />
                                </div>
                                    <div className="w-full">
                                        <label htmlFor="nominal_kas" className="label-form">Nominal KAS</label>
                                        <input type="text" id="nominal_kas" className="input-form" placeholder="" 
                                            
                                            value={nominal_kas}
                                            onChange={handleInputnominalkas}
                                        />
                                        <ErrorField errors={errors} name='nominal_kas' />
                                    </div>
                            </div>
                                <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                    <div className="w-full">
                                        <label htmlFor="nominal_danasosial" className="label-form">Nominal Dana Sosial</label>
                                        <input type="text" id="nominal_danasosial" className="input-form" placeholder="" 
                                            
                                            value={nominal_danasosial}
                                            onChange={handleInputnominaldanasosial}
                                        />
                                        <ErrorField errors={errors} name='nominal_danasosial' />
                                    </div>
                                    <div className="w-full">
                                        <label htmlFor="nominal_olahraga" className="label-form">Nominal Olahraga</label>
                                        <input type="text" id="nominal_olahraga" className="input-form" placeholder="" 
                                            
                                            value={nominal_olahraga}
                                            onChange={handleInputnominalolahraga}
                                        />
                                        <ErrorField errors={errors} name='nominal_olahraga' />
                                    </div>
                                </div>
                                
                            </div>
                            ) : (null
                        )}
                       

                        <div className="flex items-center pt-4 space-x-4">
                            <div>
                                {data.status_int == '0' ? (
                                    <button type='submit' className="btn-primary px-7">Save</button>
                                ) : (null
                                )}
                            </div>
                            {isEditable ? (
                                <div>
                                    {data.status_int == '1' ? ( 
                                        <div>
                                            <button type="button" className="text-black bg-green-500 rounded-lg text-sm p-1.5 ml-auto mr-3 inline-flex items-center hover:bg-gray-800 hover:text-white"
                                                onClick={() => handleaccept(data)}
                                            >Accept</button>
                                            <button type="button" className="text-white bg-red-600 rounded-lg text-sm p-1.5 ml-auto mr-3 inline-flex items-center hover:bg-gray-800 hover:text-white"
                                                onClick={() => onReject(data)}
                                            >Return</button>
                                        </div>
                                    ) : (null
                                    )}
                                </div>
                            ) : null }
                            
                            <button type='reset'  className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
                {isFullScreen && data_image && (
                    <FullScreenImage
                        src={data_image}
                        alt=''
                        onClose={handleCloseFullScreen}
                    />
                )}
            </div>
        </>
}

export default Payment_form_pembayaran_manual;