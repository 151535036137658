import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {getAllUser_beforeupdate, UserPayload_change_data, UserPayload_change_data_before } from '../../api/user-api';
import PaymentAPI, {  kas_pelimpahanpayload } from '../../api/payment_api';
import ErrorField from '../ErrorField';
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment, { Moment } from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LIST_STATUS_PERNIKAHAN = [
	{ id: 1, name: 'BELUM KAWIN' },
	{ id: 2, name: 'KAWIN' },
	{ id: 3, name: 'CERAI HIDUP' },
	{ id: 4, name: 'CERAI MENINGGAL' }
]
const LIST_RT = [
	{ id: 1, name: '0' }
]
const LIST_RW = [
	{ id: 1, name: '0' }
]
const LIST_AGAMA = [
	{ id: 1, name: 'ISLAM' },
	{ id: 2, name: 'KRISTEN (PROTESTAN)' },
	{ id: 3, name: 'KATOLIK' },
	{ id: 4, name: 'HINDU' },
	{ id: 5, name: 'BUDHA' },
	{ id: 6, name: 'KONGHUCU' }
]

const LIST_STATUS_KELUARGA = [
    { id: 1, name: 'KEPALA KELUARGA' },
    { id: 2, name: 'ISTRI' },
    { id: 3, name: 'ANAK' },
    { id: 4, name: 'ORANG TUA' },
    { id: 5, name: 'SAURDARA' },
    { id: 6, name: 'Lain-lain' }
]
const LIST_ADDMANUAL = [
    { id: 1, name: 'IN' },
    { id: 2, name: 'OUT' }
]

const LIST_ROLE = [
	{ id: 1, name: 'Warga' }
]

const LIST_JENISKELAMIN = [
	{ id: 1, name: 'laki-laki' },
	{ id: 2, name: 'perempuan' }
]

interface KaspelimpahanExtend extends kas_pelimpahanpayload {
    confirm_password: string
}

type Props = {
    data?: any,
    onSubmit: (data: kas_pelimpahanpayload) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const Kastambahmanual: React.FC<Props> = ({ data, onSubmit, onClose }) => {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setValue
        } = useForm<any>({ criteriaMode: "all" });

        const [payloadUserPayload_byaddress, setUserPayload_byaddress] = useState<UserPayload_change_data_before>({})
        let [payloadUserPayload_change_data, setUserPayload_change_data] = useState<UserPayload_change_data>({})
        
        // const [selectedDate, setSelectedDate] = useState('');
        const [selectedDate, setSelectedDate] = useState(new Date());
        const [selectedDate_str, setSelectedDate_str] = useState('');

        const [data_image, setdataimage] = useState('');
        const handleImageload = (event: any) => {
            event.preventDefault()
            setdataimage(event.target.files[0])
            // console.log('IMAGE CHANGE : ', event.target.files[0])
        }
        const [isFullScreen, setIsFullScreen] = useState(false);
        const [currentImage, setCurrentImage] = useState<string | null>(null);
        const handleImageClick = (src: string) => {
            setCurrentImage(src);
            setIsFullScreen(true);
        };
    
        const handleDateChanges = (value: string | Moment) => {
            // if (typeof value === 'string') {
            //     // setSelectedDate(new Date(value));
            // } else {
            //     const momentDate = moment(value);
            //     // momentDate.subtract(1, 'days');
            //     setSelectedDate(momentDate.toDate());
            // }
            console.log('> 1 ',value);
            if (typeof value === 'string') 
            {
                console.log('>> 1 ',selectedDate);
                setSelectedDate(new Date(value));
            } 
            else
            {
                const momentDate = moment(value);
                setSelectedDate(momentDate.toDate());
                console.log('>> 2 ',selectedDate);
            }
             //else {
            //     setSelectedDate(value.toDate());
            //     setSelectedDate_str(value.format('YYYY-MM-DD'));
            // }
            console.log('>>> ',selectedDate);
            setValue('identity_tanggallahir',selectedDate);
        };

        const setdefaultValue = useCallback ((obj: any) => {
            console.log()
            Object.keys(obj).map(key => {
                if(key === 'identity_agama'){
                    // console.log(key,' | ', data.identity_agama , ' - ' , LIST_AGAMA );
                    return setValue(key, LIST_AGAMA.find(item => item.name.toLowerCase() === data.identity_agama.toLowerCase())?.name, { shouldValidate: true })
                }
                else if(key === 'identity_gender'){
                    // console.log(key,' | ', data.identity_gender , ' - ' , LIST_JENISKELAMIN );
                    return setValue(key, LIST_JENISKELAMIN.find(item => item.name.toLowerCase() === data.identity_gender.toLowerCase())?.name, { shouldValidate: true })
               }
               else if(key === 'identity_statuspernikahan'){
                    // console.log(key,' | ', data.identity_statuspernikahan , ' - ' , LIST_STATUS_PERNIKAHAN );
                   return setValue(key, LIST_STATUS_PERNIKAHAN.find(item => item.name.toLowerCase() === data.identity_statuspernikahan.toLowerCase())?.name, { shouldValidate: true })
              }
              else if(key === 'status_keluarga'){
                    // console.log(key,' | ', data.status_keluarga , ' - ' , LIST_STATUS_KELUARGA );
                    return setValue(key, LIST_STATUS_KELUARGA.find(item => item.name.toLowerCase() === data.status_keluarga.toLowerCase())?.name, { shouldValidate: true })
             }else{
                    return setValue(key, obj[key], { shouldValidate: true })
                }
                
            })
        }, [setValue])


        

    const fetchUser = useCallback( async () => {
        const res = await getAllUser_beforeupdate(data as UserPayload_change_data_before)
        if(res.data){
            if(res.data.code === 0){
                payloadUserPayload_change_data= res.data.data.rows[0];
                // console.log('getAllUser_beforeupdate ' , payloadUserPayload_change_data)
                setdefaultValue(payloadUserPayload_change_data)

                const momentDate = moment(payloadUserPayload_change_data.identity_tanggallahir);
                // setSelectedDate(momentDate);
                
                const dateFromMoment = momentDate.toDate();
                setSelectedDate(dateFromMoment);

            }else{
                toast.error(res.data.message)
            }
        }else{
            toast.error('NOT FOUND')
        }
    }, [])

        useEffect(() => {
            //fetchUser()
            console.log('DATA PELIMPAHAN: ',data)
            if(Object.keys(data).length !== 0){
                setdefaultValue(data)
            }
        }, [data, setdefaultValue])
    
        const onSubmitForm: SubmitHandler<kas_pelimpahanpayload> = ({clu_code, code_generated, username, }) => {
            
            const payload = {
                clu_code, code_generated,username, 
            } as UserPayload_change_data

            onSubmit(payload)
        }
        return <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-3/5 rounded-lg shadow bg-gray-700 z-50 overflow-y-auto" style={{ maxHeight: '90vh' }}>
                    <div className="flex justify-end p-2">
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
    
                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                        <h3 className="text-xl font-medium text-white pb-4">Form Tambah data Kas</h3>
                        
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="description_by_create" className="label-form">Deskripsi Bukti</label>
                                <input type="text" id="description_by_create" className="input-form" placeholder=""
                                    {...register('description_by_create', {
                                        required: 'description_by_create is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='description_by_create' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="description_by_create" className="label-form">Nominal</label>
                                <input type="text" id="description_by_create" className="input-form" placeholder=""
                                    {...register('description_by_create', {
                                        required: 'description_by_create is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='description_by_create' />
                            </div>
                            
                        </div>
                        <div className="w-full">
                                <label  className="label-form">Tipe Tambah</label>
                                <select id="type_add" className="input-form" {...register('type_add', {required: 'type_add is required'})}>
                                    <option value=''>- Select Role -</option>
                                    {LIST_ADDMANUAL.map((item, key) =>
                                        <option 
                                            key={key} 
                                            value={item.name}>
                                                {item.name.toUpperCase()}
                                        </option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='type_add' />
                            </div>
                        <div className="w-full">
                                    <label htmlFor="images" className="label-form">Upload Bukti Bayar</label>
                                    <input type="file" id="images" 
                                        className="input-form" 
                                        accept='image/jpeg,image/jpg,image/png' 
                                        onChange={handleImageload}
                                        />
                                    
                                    <ErrorField errors={errors} name='images' />
                                </div>
                        <div className="flex items-center pt-4 space-x-4">
                            <button type='submit' className="btn-primary px-7">Save</button>
                            <button type='reset'  className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
}

export default Kastambahmanual;