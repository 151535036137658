// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createNewUser, updateUser_data, deleteUser, getAllUser_byblok, updateUser,updateUser_password, UserPayload,  UserPayload_byblok, UserPayload_change_data, UserPayload_change_password } from '../../../api/user-api'; //getAllUser,getAllUser_byaddress, 
import UserForm from '../../../components/modals/UserForm';
import UserForm_changepass from '../../../components/modals/UserForm_changepass';
import SearchField from '../../../components/SearchField';
import TableFull from '../../../components/Table';
import ErrorField from '../../../components/ErrorField';
import ContactUsAPI, { ContactUsPayload } from '../../../api/contactus-api';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Registerapi_batch, master_bacth } from '../../../api/common-api';
import UserImport from '../../../components/modals/UserImport';
import XLSImporters from '../../../components/XLSImporter';

type Props = {};

const Mrt_1_manage_warga: React.FC<Props> = () => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const [jsonDataArray_set, setJsonDataArray] = useState< { [key: string]: any }[]>([]);
    const [modalUserImport, showModalUserImport] = useState(false)
    
    const [payloadinput_bacth_clucheck, setinput_bacth] = useState<master_bacth>({
        clu_code: '',
        username: '',
        data_batch: []
      });
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [modalUser_changepass, showmodalUser_changepass] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataUser, setDataUser] =  useState<DataItem[]>([]);
    const [filterData, setFilterData] =  useState<DataItem[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserPayload>({})
    const [payloadUserPayload_byblok, set_UserPayload_byblok] = useState<UserPayload_byblok>({})
    const [payloadUserPayload_change_data, set_UserPayload_change_data] = useState<UserPayload_change_data>({})
    const [payloadUserPayload_change_password, set_UserPayload_change_password] = useState<UserPayload_change_password>({})
    
    const [dataupload_batch, set_dataupload_batch] = useState('')
    const [data_codegenerated, set_data_codegenerated] = useState('')
    const [data_clucode, set_data_clucode] = useState("")
    const [data_blok_name, set_data_blok_name] = useState('')
    const [data_number_home, set_data_number_home] = useState('')

    const userLocal = localStorage.getItem('dataUser')
    const user = userLocal? JSON.parse(userLocal || '') : null;

    // payloadUserPayload_byblok.clu_code = user.clu_code;
    payloadUserPayload_byblok.username = user.username;
    payloadUserPayload_byblok.code_generated = user.code_generated;
    payloadUserPayload_byblok.status_user = "1";

    const [FilterDatafinalxls, setFilterDatafinalxls] = useState<DataExcelprint[]>([]);

    interface DataExcelprint 
    {
        id: number;
        rumah_namablok: string;
        rumah_nomor: string;
        nama: string;
        user: string;
        status_keluarga: string;
        umur: number;
    }
      
    const navigate = useNavigate()

    const handleimportuser = async (data: any) => {
        showLoader(true)
        const newValue: master_bacth = {
            clu_code : user.clu_code,
            username : user.username,
            data_batch : jsonDataArray_set,
        }
        setinput_bacth(newValue);

        const jsonStringuser = JSON.stringify(JSON.parse(userLocal || ''));


        let res = null
        if (payloadinput_bacth_clucheck != undefined) {
            payloadinput_bacth_clucheck.clu_code = user.clu_code;
            payloadinput_bacth_clucheck.username = user.username;
            payloadinput_bacth_clucheck.data_batch = jsonDataArray_set;
            // console.log("> Register ")
            const res = await Registerapi_batch(payloadinput_bacth_clucheck as master_bacth);
            // console.log("< Register :", res)
            alert(res.data.message)
            //alert(`${res.config?.url} ${res.message}`)
        }
        else{
            alert('Belum diprocess')
        }
    }

    const downloadtemplate = () => {
        toast.error("Fitur Belum siap : Download Template")
    }

    const hapusdatakeluarga = () => {
        toast.error("Fitur Belum siap : Hapus Data Keluarga")
    }
    
    const downloaddData = () => {
        if(filterData != undefined)
        {
            // filterData.forEach((dataItem, index) => {
            //     console.log(index, dataItem); // For example, log each item to the console
            // }); 
        
            const worksheet = XLSX.utils.json_to_sheet(filterData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'Data_warga.xlsx');
        }
    }

    const handleImport = (todo: number, data: any[]) => {
        if(modalUserImport == false)
        {
            showModalUserImport(true)
        }
        if(data != null)
        {
            const jsonDataArray: { [key: string]: any }[] = [];
            data.forEach((row: any[], index:number) => 
            {
                if (row.length > 1) 
                {
                    if(index > 0)
                    {
                        const jsonDataObject: { [key: string]: any } = {};
                        jsonDataObject['no']                = index;
                        jsonDataObject['timestamp']         = row[0];
                        jsonDataObject['blok_rumah']        = row[1];
                        jsonDataObject['nomor_rumah']       = row[2];
                        jsonDataObject['nomor_kk']          = row[3];
                        jsonDataObject['nomor_nik']         = row[4];
                        jsonDataObject['nama_lengkap']      = row[5];
                        jsonDataObject['jenis_kelamin']     = row[6];
                        jsonDataObject['tempat_lahir']      = row[7];
                        jsonDataObject['tangggal_lahir']    = row[8];
                        jsonDataObject['agama']             = row[9];
                        jsonDataObject['pekerjaan']         = row[10];
                        jsonDataObject['kewarganegaraan']   = row[11];
                        jsonDataObject['status_tempat_tinggal']   = row[12];
                        jsonDataObject['email']             = row[13];
                        jsonDataObject['nomor_hp']          = row[14];
                        jsonDataObject['alamat_ktp']        = row[15];
                        jsonDataObject['upload_kk']         = row[16];
                        jsonDataObject['email_input']       = row[17];
                        jsonDataObject['status_hubungan_keluarga'] = row[18];
                        jsonDataObject['status_pernikahan'] = row[19];
                        jsonDataObject['pendidikan_terkahir']   = row[20];
                        jsonDataObject['username']          = row[21];
                        jsonDataObject['password']          = row[22];
                        jsonDataObject['rt']                = row[23];
                        jsonDataObject['rw']                = row[24];
                        
                        jsonDataArray.push(jsonDataObject);
                    }
                }
            });
            setJsonDataArray(jsonDataArray);
            const jsonString = JSON.stringify(jsonDataArray);
            set_dataupload_batch(jsonString)
        }
        else
        {
            if(todo == 1)
            {
                console.log('Failed get data', data);
            }
            else
            {
                console.log('Cancel');
            }
        }
        // Process the imported data as needed
    };

    interface DataItem {
        description_by_create?: string;
        nama_kepala_keluarga?: string;
        opr_subject_var?: string;
        // tambahkan properti lain yang ada dalam objek
    }
    const fetchUser = useCallback( async () => {
        
        set_data_clucode(user.clu_code);
        set_data_codegenerated(user.code_generated);
        set_data_blok_name(user.blok_name);
        set_data_number_home(user.number_home);
        showLoader(true)

        // console.log("USER LOCAL : "+payloadUserPayload_byblok.clu_code)
        const res = await getAllUser_byblok(payloadUserPayload_byblok as UserPayload_byblok)

        showLoader(false)
        // console.log('Fetch Data Warga :', res)
        if(res.data){
            if(res.data.code === 0){
                const dataWithNoApp = res.data.data.map((item: DataItem, index: number) => ({
                    ...item,
                    no_app: index + 1
                }));
                setDataUser(dataWithNoApp);
                setFilterData(dataWithNoApp);
                // setDataUser(res.data.data)
                // setFilterData(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchUser()
        }
    }, [navigate])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const LIST_ROLE = [
        { id: 99, name: 'Super Admin' },
        { id: 1, name: 'Administrator' }
    ]

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await ContactUsAPI.get()

        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data){
            if(res.data.code === 0){
                setdefaultValue(res.data.data)
            } else if(res.data.code === 99){
                navigate('/auth')
            }else{
                toast.error(res.data.message)
            }
        }else{
            fetchData()
        }
    }, [navigate, setdefaultValue])

    const onSubmitForm: SubmitHandler<master_bacth> = async ({clu_code, username, data_batch}) => {
        showLoader(true)
        const res = await Registerapi_batch(payloadinput_bacth_clucheck as master_bacth);
        showLoader(false)
        // console.log('Fetch ContactUs :', res)
        if(res.data)
        {
            toast.error(res.data.code+" | "+ res.data.message)
        }
        else
        {
            toast.error("Failed")
        }
    }

    const handleEditData =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        // console.log("EDITTTTT", selectedData)
        setIsUpdate(true)
        showModalUser(true)

    }
    
    const handlepassword =  (selectedData: UserPayload) => {
        setSelectedUser(selectedData)
        // setIsUpdate(true)
        showmodalUser_changepass(true)
    }

    const handleReceiveDataForm = async (data: UserPayload_change_data) => {
        showLoader(true)
        
        let res = null
        res = await updateUser_data(selectedUser.id || 1, data)

        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }
    
    const handleReceiveDataForm_changepass = async (data: UserPayload_change_password) => {
        showLoader(true)
        
        //alert(data.username + " | " + data.password + " | " + data.confirm_password)
        //console.log(data)
        let res = null
        res = await updateUser_password(data)

        // console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
                resetForm()
            }else{
                toast.error(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (data: UserPayload) => {
        const res = await deleteUser(data.id || 1)

        // console.log("DELETE USER :", res)
        if(res.data){
            if(res.data.code === 0){
                toast.success('Success')
                fetchUser()
            }else{
                toast.error(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        setSelectedUser({})
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
        showModalUserImport(false)
        showmodalUser_changepass(false)
    }

    const columns = [
        {
            Header: () => <span className='p-4'>No</span>,
            Footer: 'no_app',
            accessor: 'no_app',
            Cell: ({ value }: any) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Blok</span>,
            Footer: 'blok_name',
            accessor: 'blok_name',
            Cell: ({ value }: any) =>  <div className='text-left  pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>No.Rumah</span>,
            Footer: 'number_home',
            accessor: 'number_home',
            Cell: ({ value }: any) =>  <div className='text-left  pl-4'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Name</span>,
            Footer: 'Fullname',
            accessor: 'fullname',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Username</span>,
            Footer: 'Username',
            accessor: 'username',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Status Keluarga</span>,
            Footer: 'status_keluarga',
            accessor: 'status_keluarga',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Umur</span>,
            Footer: 'age',
            accessor: 'age',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: () => <span className='p-4'>Status Akun</span>,
            Footer: 'status_user',
            accessor: 'status_user',
            Cell: ({ value }: any) =>  <div className='text-left'>{value}</div>,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}: any) => {
                const data = row.original
                return (
                    <div className='text-xl scale-110 space-x-1 2xl:space-x-1.5'>
                        <i className="ri-edit-box-line text-primary hover:text-green-400 cursor-pointer" onClick={() =>handleEditData(data)}></i>
                        <i className="ri-lock-password-fill text-primary hover:text-green-400 cursor-pointer" onClick={() =>handlepassword(data)}></i>
                        {/* <i className="ri-delete-bin-2-line text-red-500 hover:text-red-400 cursor-pointer" onClick={()=>handleDeleteItem(data)}></i> */}
                    </div>
                )
                return <></>
            }
        }
    ]

    const handleSearch = (event: any) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            const filtered = newData.filter((item: any) => {
                return (
                    item.blok_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.fullname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.username?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.status_keluarga?.toLowerCase().includes(event.target.value.toLowerCase()) 
                )
            }).map((item: DataItem, index: number) => ({
                ...item,
                no_app: index + 1 // Menambahkan nomor increment
            }));
            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <>
            <div className='flex item-center flex-col p-6'>
                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5'>
                        <h1 className='font-bold'>Data Warga</h1>
                    </div>
                    <div className="flex" style={{ marginBottom: '20px' }}>
                        <button type='reset' className="btn-secondary" onClick={hapusdatakeluarga}>Hapus Data Keluarga</button>
                    </div>
                    <div className="md:flex md:space-x-10 text-white space-y-5 md:space-y-0 mb-3">
                        <div className="flex-1" style={{ color: 'black' }}>
                            <SearchField placeholder='Search user...' onChange={handleSearch} />
                        </div>
                        <div className="flex justify-end" style={{ color: 'black' }}>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <button type='reset' className="btn-secondary" onClick={downloadtemplate}>Download Template</button>
                            </div>
                            <div className="flex" style={{ marginRight: '10px' }}>
                                <XLSImporters isShow={modalUserImport} onImport={handleImport} />
                            </div>
                        </div>
                    </div>

                    <TableFull dataTable={filterData} columnTable={columns} />
                    <div className="flex justify-end" style={{ marginTop: '10px' }}>
                        <button type='reset' className="btn-secondary" onClick={downloaddData}>Download Data</button>
                    </div>
                </div>
            </div>
            {modalUser && <UserForm data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalUser_changepass && <UserForm_changepass data={selectedUser} onClose={resetForm} onSubmit={handleReceiveDataForm_changepass} />}
            {modalUserImport && <UserImport jsonDataArray_show={jsonDataArray_set} data={selectedUser} onClose={resetForm} onSubmit={handleimportuser} />}
       

        </>
    );
}

export default Mrt_1_manage_warga;