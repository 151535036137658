import axios from "axios"
import { baseURL } from "./common-api"

export interface payload_sidebar{
    code_generated?: string,
    username?: string
}

export interface sidebar_data{
    name_var: string,
    url_var: string,
    icon_var: string,
    children: MenuItem[];
}

export interface MenuItem{
    name_var: string,
    url_var: string,
    icon_var: string
}

class sidebar_api 
{
    static getdata = async (payload: payload_sidebar) => {
        try {
            // console.log('getdata ',payload)
            const data = await axios.post(baseURL + '/api/sidebar', payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
}

export default sidebar_api
 